import { Route, Routes } from "react-router-dom";

import { StandardContent } from "@/components/App";

import AppointmentCancelPage from "./Cancel";
import AppointmentCanceledPage from "./Canceled";
import AppointmentDetailsPage from "./Details";

export default function AppointmentPage() {
  return (
    <StandardContent>
      <Routes>
        <Route path=":id/canceled" element={<AppointmentCanceledPage />} />
        <Route path=":id/cancel" element={<AppointmentCancelPage />} />
        <Route path=":id" element={<AppointmentDetailsPage />} />
      </Routes>
    </StandardContent>
  );
}
