import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";

import BellIcon from "./bell.svg?react";

type NotificationContextType = {
  showNotification: (message: string, type?: "success" | "error") => void;
};

const NotificationContext = createContext({} as NotificationContextType);

const MS_UNTIL_AUTO_CLOSE = 5000;

type Props = { children: React.ReactNode };

export function NotificationProvider({ children }: Props) {
  const [notification, setNotification] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);

  useEffect(() => {
    if (notification) {
      setTimeout(() => setNotification(null), MS_UNTIL_AUTO_CLOSE);
    }
  }, [notification]);

  const showNotification = useCallback(
    (message: string, type: "success" | "error" = "success") => {
      setNotification({ message, type });
    },
    [setNotification],
  );

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {notification && (
        <Notification type={notification.type}>
          {notification.message}
        </Notification>
      )}

      {children}
    </NotificationContext.Provider>
  );
}

function Notification({
  children,
  type,
}: React.ComponentPropsWithRef<"div"> & { type: "success" | "error" }) {
  return (
    <div className="fixed left-[50%] top-0 flex w-full max-w-lg translate-x-[-50%] flex-col justify-center px-10 py-4">
      <div
        className={twMerge(
          "flex justify-between bg-capitalino-cactus px-4 py-2 text-sm text-white",
          type === "error" && "bg-sunset-orange",
        )}
      >
        {children} <BellIcon className="ml-2" />
      </div>
    </div>
  );
}

export function useNotification() {
  const { showNotification } = useContext(NotificationContext);

  return showNotification;
}
