import { zodResolver } from "@hookform/resolvers/zod";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { format, subYears } from "date-fns";
import { usePlacesWidget } from "react-google-autocomplete";
import { useForm } from "react-hook-form";
import { parseGooglePlace } from "utils/google-places";
import { z } from "zod";

import { StandardContent } from "@/components/App";
import { useAuth } from "@/components/Auth";
import Button from "@/components/Button";
import ErrorText from "@/components/ErrorText";
import FormField from "@/components/FormField";
import FormFields from "@/components/FormFields";
import Header from "@/components/Header";
import { Input, Select } from "@/components/Input";
import Label from "@/components/Label";
import Loader from "@/components/Loader";
import { useNotification } from "@/components/Notification";
import { PageTitle } from "@/constants/pages";
import { usePageEventTracking } from "@/hooks/event-tracking";
import { useUpdateUserMutation } from "@/hooks/user";

const schema = z.object({
  first_name: z.string().min(1, { message: "First name is required" }),
  last_name: z.string().min(1, { message: "Last name is required" }),
  address: z.string().min(1, { message: "Address is required" }),
  city: z.string().min(1, { message: "City is required" }),
  state: z.string().min(1, { message: "State is required" }),
  postal_code: z.string().min(1, { message: "Zip is required" }),
  emergency_name: z
    .string()
    .min(1, { message: "Emergency Contact Name is required" }),
  emergency_phone: z.string().min(10, {
    message: "10 digit emergency contact phone number is required",
  }),
  mobile_phone: z.string().min(10, {
    message: "10 digit emergency contact phone number is required",
  }),
  gender: z.string().min(1, { message: "Gender is required" }),
  marketingEmails: z.boolean(),
  marketingTexts: z.boolean(),
  dateOfBirth: z.string().min(1, { message: "Date of Birth is required" }),
});

const minDOB = format(subYears(new Date(), 120), "yyyy-MM-dd");
const maxDOB = format(new Date(), "yyyy-MM-dd");

type Schema = z.infer<typeof schema>;

export default function ProfilePage() {
  const { user, waiver, hasIncompletedAccountInformation } = useAuth();
  const notify = useNotification();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { isValid },
  } = useForm<Schema>({
    mode: "onBlur",
    resolver: zodResolver(schema),
    defaultValues: {
      first_name: user?.first_name ?? "",
      last_name: user?.last_name ?? "",
      address: user?.address ?? "",
      city: user?.city ?? "",
      state: user?.state ?? "",
      postal_code: user?.postal_code ?? "",
      emergency_name: user?.emergency_name ?? "",
      emergency_phone: user?.emergency_phone ?? "",
      gender: user?.gender ?? "",
      mobile_phone: user?.mobile_phone ?? "",
      marketingTexts: user?.notification_preferences.promo.text ?? false,
      marketingEmails: user?.notification_preferences.promo.email ?? false,
      dateOfBirth: user?.dob ?? undefined,
    },
  });

  const updateUserMutation = useUpdateUserMutation();

  const onSubmit = handleSubmit(
    ({ marketingEmails, marketingTexts, ...data }) => {
      updateUserMutation.mutate(
        {
          ...data,
          notification_preferences: {
            promo: {
              email: marketingEmails,
              text: marketingTexts,
            },
          },
        },
        {
          onSuccess: () => {
            notify(`Your profile changes have been saved`);
          },
        },
      );
    },
  );

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    options: {
      types: ["address"],
    },
    onPlaceSelected: (place) => {
      const results = parseGooglePlace(place);

      if (results) {
        if (ref.current) {
          ref.current.value = results.address;
        }
        setValue("address", results.address);
        setValue("city", results.city);
        setValue("state", results.state);
        setValue("postal_code", results.postal_code);
      }
    },
  });

  usePageEventTracking();

  useDocumentTitle(PageTitle.ACCOUNT_PROFILE);

  return (
    <StandardContent>
      <form onSubmit={onSubmit}>
        <Header className="mb-6">Account details and waiver</Header>

        <FormFields className="mb-10">
          <div className="flex flex-wrap gap-4 sm:flex-nowrap">
            <FormField className="basis-full">
              <Label htmlFor="firstName">First Name</Label>
              <Input id="firstName" type="text" {...register("first_name")} />
            </FormField>

            <FormField className="basis-full">
              <Label htmlFor="lastName">Last Name</Label>
              <Input id="lastName" type="text" {...register("last_name")} />
            </FormField>
          </div>

          <FormField>
            <Label htmlFor="dateOfBirth">Date of Birth</Label>
            <Input
              id="dateOfBirth"
              type="date"
              min={minDOB}
              max={maxDOB}
              {...register("dateOfBirth")}
            />
          </FormField>

          <FormField>
            <Label htmlFor="gender">Gender</Label>
            <Select id="gender" {...register("gender")}>
              <option></option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="None">Prefer not to say</option>
            </Select>
          </FormField>

          <FormField>
            <Label htmlFor="mobile_phone">Phone</Label>
            <Input id="mobile_phone" type="tel" {...register("mobile_phone")} />
          </FormField>

          <FormField>
            <Label htmlFor="address">Address</Label>
            <Input
              id="address"
              type="text"
              ref={ref}
              defaultValue={user?.address ?? ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setValue("address", event.target.value)
              }
            />
          </FormField>

          <FormField>
            <Label htmlFor="city">City</Label>
            <Input id="city" type="text" {...register("city")} />
          </FormField>

          <div className="flex flex-wrap gap-4 sm:flex-nowrap">
            <FormField className="basis-full">
              <Label htmlFor="state">State</Label>
              <Input id="state" type="text" {...register("state")} />
            </FormField>

            <FormField className="basis-full">
              <Label htmlFor="zip">Zip</Label>
              <Input id="zip" type="text" {...register("postal_code")} />
            </FormField>
          </div>

          <FormField>
            <Label htmlFor="emergency_name">Emergency Contact Name</Label>
            <Input
              id="emergency_name"
              type="text"
              {...register("emergency_name")}
            />
          </FormField>

          <FormField>
            <Label htmlFor="emergency_phone">Emergency Contact Phone</Label>
            <Input
              id="emergency_phone"
              type="tel"
              {...register("emergency_phone")}
            />
          </FormField>

          <div className="flex gap-2">
            <Input
              id="marketingTexts"
              type="checkbox"
              {...register("marketingTexts")}
            />
            <Label htmlFor="marketingTexts">
              By checking this box you are consenting to receive money saving
              deals from Restore Hyper Wellness, including text messages and
              calls. Consent is not a condition of purchase. You can opt out of
              the text messages at any time by replying “STOP.” Message and data
              rates may apply.
            </Label>
          </div>
          <div className="flex gap-2">
            <Input
              id="marketingEmails"
              type="checkbox"
              {...register("marketingEmails")}
            />
            <Label htmlFor="marketingEmails">
              I want to receive exclusive promotions emails from Restore Hyper
              Wellness. We respect your privacy and will never share your
              information with third parties without your permission.
            </Label>
          </div>
          {updateUserMutation.isError && (
            <ErrorText error={updateUserMutation.error} />
          )}

          {updateUserMutation.isPending ? (
            <Loader />
          ) : (
            <Button type="submit" disabled={!isValid}>
              Save
            </Button>
          )}
        </FormFields>
      </form>

      <div className="flex flex-col gap-1 bg-linx-white p-4">
        {waiver.state === "VALID" ? (
          <>
            <p className="font-semibold">No Action is required</p>

            {waiver.expiresAt && (
              <p>
                Your waiver is valid until{" "}
                <strong>{format(waiver.expiresAt, "MMMM do, yyyy")}</strong>.
              </p>
            )}

            <p>No action is needed at this time.</p>
          </>
        ) : (
          <>
            <p className="font-semibold">Action is required</p>

            {hasIncompletedAccountInformation ? (
              <p>
                Please complete all account information before starting waiver
              </p>
            ) : (
              <>
                {waiver.state === "NOT_SIGNED" && (
                  <>
                    <p>
                      We require you to complete a mandatory waiver before you
                      can receive any of our services.
                    </p>
                    <p>
                      Please take the time to complete a waiver prior to
                      attending your appointment.
                    </p>
                  </>
                )}

                {waiver.state === "EXPIRED" && (
                  <>
                    <p>
                      We require you to complete a mandatory waiver before you
                      can receive any of our services.
                    </p>
                    {waiver.expiresAt && (
                      <p>
                        Your waiver will expire{" "}
                        <strong>
                          {format(waiver.expiresAt, "MMMM do, yyyy")}
                        </strong>
                        .
                      </p>
                    )}
                    <p>
                      Please take the time to complete a new waiver prior to
                      attending your appointment.
                    </p>
                  </>
                )}
              </>
            )}
            <Button
              type="button"
              onClick={() => {
                // Using an onClick handler here instead of a link to use the buttons disabled state
                window.open(
                  `${
                    import.meta.env.VITE_WAIVER_URL
                  }?pow_customer=${user?.guid}&on_success_redirect_url=${
                    window.location.href
                  }`,
                  "_blank",
                );
              }}
              className="mt-2"
              disabled={hasIncompletedAccountInformation}
            >
              Complete waiver
            </Button>
          </>
        )}
      </div>
    </StandardContent>
  );
}
