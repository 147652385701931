import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe, SetupIntent } from "@stripe/stripe-js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import Button from "@/components/Button";
import FormField from "@/components/FormField";
import FormFields from "@/components/FormFields";
import { Input } from "@/components/Input";
import Loader from "@/components/Loader";
import { confirmIntent, createIntent } from "@/services/payment";

const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

function useConfirmSetupIntent() {
  const stripe = useStripe();
  const elements = useElements();

  return useMutation({
    mutationFn: async () => {
      if (!stripe || !elements) {
        return;
      }

      const { error: submitError } = await elements.submit();

      if (submitError) {
        throw new Error(submitError.message);
      }

      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        redirect: "if_required",
      });

      if (error) {
        throw new Error(error.message);
      }

      if (typeof setupIntent === "undefined") {
        throw new Error("Setup intent not found");
      }

      return setupIntent;
    },
  });
}

function useAddPaymentMutation() {
  return useMutation({
    mutationFn: async ({
      isPrimary,
      setupIntent,
    }: {
      isPrimary: boolean;
      setupIntent?: SetupIntent;
    }) => {
      if (setupIntent?.payment_method) {
        await confirmIntent({
          primary: isPrimary,
          payment_method_id:
            typeof setupIntent.payment_method === "string"
              ? setupIntent.payment_method
              : setupIntent.payment_method.id,
        });
      }
    },
  });
}

const QUERY_KEY = "setup-intent";

export function AddCardForm({ onSuccess }: { onSuccess: () => void }) {
  const queryClient = useQueryClient();
  const intentQuery = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: createIntent,
  });

  function handleSuccess() {
    queryClient.removeQueries({ queryKey: [QUERY_KEY] });
    onSuccess();
  }

  if (intentQuery.isPending || !intentQuery.data) {
    return <Loader />;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: intentQuery.data.secret,
        fonts: [
          {
            cssSrc: "https://fonts.googleapis.com/css2?family=Poppins",
          },
        ],
        appearance: {
          variables: {
            borderRadius: "0.125rem",
            fontFamily: "Poppins, sans-serif",
            fontSizeBase: "16px",
            colorDanger: "#FF6056",
          },
          rules: {
            ".Label": {
              fontWeight: "600",
              color: "#055577",
            },
            ".Input": {
              border: "1px solid rgb(24 63 82 / 0.2)",
              padding: "0.75rem",
              color: "rgb(75 85 99)",
              borderRadius: "0.5rem",
            },
            ".Input:focus": {
              outline: "1px solid rgb(5 85 119)",
            },
          },
        },
      }}
    >
      <AddPaymentMethodForm onSuccess={handleSuccess} />
    </Elements>
  );
}

function AddPaymentMethodForm({ onSuccess }: { onSuccess: () => void }) {
  const { register, handleSubmit } = useForm<{
    isPrimary: boolean;
  }>({
    defaultValues: {
      isPrimary: true,
    },
  });
  const paymentMutation = useAddPaymentMutation();
  const confirmSetupIntentMutation = useConfirmSetupIntent();

  const onSubmit = handleSubmit(({ isPrimary }) => {
    confirmSetupIntentMutation.mutate(undefined, {
      onSuccess: (setupIntent) => {
        paymentMutation.mutate(
          { setupIntent, isPrimary },
          {
            onSuccess,
          },
        );
      },
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <FormFields>
        <PaymentElement
          options={{
            terms: { card: "never" },
            wallets: { applePay: "never", googlePay: "never" },
          }}
        />

        <FormField className="mb-4">
          <div className="flex gap-2">
            <Input id="isPrimary" type="checkbox" {...register("isPrimary")} />
            <label htmlFor="isPrimary">Set as default payment method.</label>
          </div>
        </FormField>

        {confirmSetupIntentMutation.isPending || paymentMutation.isPending ? (
          <Loader />
        ) : (
          <Button type="submit">Add card</Button>
        )}
      </FormFields>
    </form>
  );
}
