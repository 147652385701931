import { twMerge } from "tailwind-merge";

import { IconButton } from "@/components/Button";
import Icon from "@/components/Icon";
import type { Store } from "@/services/booking";

type Props = {
  store: Store;
  className?: string;
};

export default function StoreCard({ store, className }: Props) {
  return (
    <div
      className={twMerge("flex justify-between items-center mb-8", className)}
    >
      <p>
        <strong>{store.description}</strong>
        <br />
        {store.location.address}
        <br />
        {store.location.city}, {store.location.state} {store.location.zip}
      </p>

      <div className="flex gap-4">
        <IconButton
          as="a"
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            `${store.location.address}, ${store.location.city}, ${store.location.state} ${store.location.zip}`,
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Directions"
        >
          <Icon name="map" aria-hidden />
        </IconButton>
        <IconButton as="a" href={`mailto:${store.email}`} aria-label="Email">
          <Icon name="mail" aria-hidden />
        </IconButton>
        <IconButton as="a" href={`tel:${store.phone}`} aria-label="Phone">
          <Icon name="phone" aria-hidden />
        </IconButton>
      </div>
    </div>
  );
}
