import { useDocumentTitle } from "@uidotdev/usehooks";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";

import AppointmentCard from "@/components/AppointmentCard";
import Button from "@/components/Button";
import Header from "@/components/Header";
import Link from "@/components/Link";
import Loader from "@/components/Loader";
import { ExternalLinks } from "@/constants/links";
import { PageRoute, PageTitle } from "@/constants/pages";
import { useAppointmentQuery } from "@/hooks/appointments";
import { usePageEventTracking } from "@/hooks/event-tracking";
import { useCancelAppointmentMutation, useStoreQuery } from "@/hooks/stores";

export default function AppointmentCancelPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const appointmentQuery = useAppointmentQuery(id);
  const storeQuery = useStoreQuery(appointmentQuery.data?.store_code);

  const cancelAppointmentMutation = useCancelAppointmentMutation();

  usePageEventTracking({
    service_name: appointmentQuery.data?.service_description,
    service_date: appointmentQuery.data?.start_datetime,
  });

  useDocumentTitle(
    PageTitle.APPOINTMENT_CANCEL(
      appointmentQuery.data?.service_description ?? "",
    ),
  );

  if (!id) {
    return <Navigate to={PageRoute.HOME} />;
  }

  if (
    !(appointmentQuery.isSuccess && storeQuery.isSuccess) ||
    !storeQuery.data
  ) {
    return <Loader />;
  }

  return (
    <>
      <Header className="mb-6 text-sunset-orange">
        Are you sure you want to cancel?
      </Header>

      <AppointmentCard
        className="mb-8"
        date={appointmentQuery.data.start_datetime}
        serviceName={appointmentQuery.data.service_description}
        storeName={storeQuery.data.description}
      />

      <p className="mb-2 font-semibold">Please note:</p>

      <p className="mb-8">
        If this cancellation occurs within 24 hours of your appointment start
        time, a late cancellation fee may apply. Visit
        <Link href={ExternalLinks.TERMS} target="_blank">
          restore.com/terms
        </Link>{" "}
        for information.
      </p>

      {cancelAppointmentMutation.isPending ? (
        <Loader />
      ) : (
        <div className="mb-4 flex flex-col gap-4">
          <Button
            type="button"
            color="red"
            onClick={() =>
              cancelAppointmentMutation.mutate(id, {
                onSuccess: () => {
                  navigate(PageRoute.APPOINTMENT_CANCELED(id ?? ""));
                },
              })
            }
          >
            Confirm cancellation
          </Button>
          <Button as={NavLink} to={PageRoute.APPOINTMENT(id)} color="blue">
            Keep appointment
          </Button>
        </div>
      )}
    </>
  );
}
