import { zodResolver } from "@hookform/resolvers/zod";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

import { useAuth } from "@/components/Auth";
import Button from "@/components/Button";
import ErrorText from "@/components/ErrorText";
import FieldField from "@/components/FormField";
import FormFields from "@/components/FormFields";
import Header from "@/components/Header";
import { Input } from "@/components/Input";
import Label from "@/components/Label";
import Loader from "@/components/Loader";
import { useNotification } from "@/components/Notification";
import PasswordRequirements from "@/components/PasswordRequirements";
import { PageTitle } from "@/constants/pages";
import { useChangePasswordMutation } from "@/hooks/user";

const schema = z.object({
  code: z.string().min(1, { message: "Code is required" }),
  password: z
    .string()
    .min(8, { message: "Password must be at least 8 characters" })
    .regex(/[A-Z]/, "Password must contain a uppercase letter")
    .regex(/[a-z]/, "Password must contain a uppercase letter")
    .regex(/[0-9]/, "Password must contain a number")
    .regex(
      /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
      "Password must contain a special character (e.g. !@#$)",
    ),
});

type Schema = z.infer<typeof schema>;

export default function SetNewPasswordPage() {
  const [searchParams] = useSearchParams();
  const notify = useNotification();
  const { signInWithToken } = useAuth();
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      code: searchParams.get("code") ?? "",
    },
  });

  const changePasswordMutation = useChangePasswordMutation();

  const onSubmit = handleSubmit(({ code, password }) => {
    changePasswordMutation.mutate(
      { code, password },
      {
        onSuccess: (res) => {
          notify(`Your password has been updated`);
          signInWithToken(res);
        },
      },
    );
  });

  const password = watch("password");

  useDocumentTitle(PageTitle.AUTH_SET_PASSWORD);

  return (
    <form onSubmit={onSubmit}>
      <Header className="mb-8">Reset Your Password</Header>

      <p className="mb-6">
        We have emailed you a password reset link. Please check your email or
        spam folder for it.
      </p>

      <FormFields>
        <FieldField>
          <Label htmlFor="code">Code</Label>
          <Input
            id="code"
            type="text"
            disabled={changePasswordMutation.isPending}
            {...register("code")}
          />
        </FieldField>

        <FieldField>
          <Label htmlFor="password">New Password</Label>
          <Input
            id="password"
            type="password"
            disabled={changePasswordMutation.isPending}
            {...register("password")}
          />
          <PasswordRequirements password={password} />
          {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
        </FieldField>

        {changePasswordMutation.isError && (
          <ErrorText error={changePasswordMutation.error} />
        )}

        {changePasswordMutation.isPending ? (
          <Loader />
        ) : (
          <Button type="submit" disabled={!isValid}>
            Next
          </Button>
        )}
      </FormFields>
    </form>
  );
}
