import React from "react";
import { twMerge } from "tailwind-merge";

type Props<T extends React.ElementType> = React.ComponentPropsWithoutRef<T> & {
  as?: T;
  className?: string;
};

export default function Header<T extends React.ElementType>({
  as,
  className,
  ...rest
}: Props<T>) {
  const Component = as ?? "h1";

  return (
    <Component
      className={twMerge(
        "font-poppins font-semibold text-3xl text-studer-blue",
        className,
      )}
      {...rest}
    />
  );
}
