import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  deletePaymentMethod,
  getPaymentMethods,
  setPaymentMethodAsPrimary,
} from "@/services/payment";

export const PAYMENT_MENTHODS_QUERY_KEY = "payment-methods";

export const usePaymentMethodsQuery = () =>
  useQuery({
    queryKey: [PAYMENT_MENTHODS_QUERY_KEY],
    queryFn: getPaymentMethods,
    select(data) {
      return data.sort((a, b) => {
        if (a.is_primary) {
          return -1;
        }

        if (b.is_primary) {
          return 1;
        }

        return 0;
      });
    },
  });

export const useRemovePaymentMethodMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePaymentMethod,
    onSuccess: ({ id }) => {
      queryClient.setQueryData<Awaited<ReturnType<typeof getPaymentMethods>>>(
        [PAYMENT_MENTHODS_QUERY_KEY],
        (oldData) =>
          oldData
            ? oldData.filter((paymentMethod) => paymentMethod.id !== id)
            : [],
      );
    },
  });
};

export const useSetPaymentMethodAsPrimaryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: setPaymentMethodAsPrimary,
    onSuccess: ({ id }) => {
      queryClient.setQueryData<Awaited<ReturnType<typeof getPaymentMethods>>>(
        [PAYMENT_MENTHODS_QUERY_KEY],
        (oldData) =>
          oldData
            ? oldData.map((paymentMethod) =>
                paymentMethod.id === id
                  ? { ...paymentMethod, is_primary: true }
                  : { ...paymentMethod, is_primary: false },
              )
            : [],
      );
    },
  });
};
