import { zodResolver } from "@hookform/resolvers/zod";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { z } from "zod";

import { useAuth } from "@/components/Auth";
import Button from "@/components/Button";
import DownloadAppCTA from "@/components/DownloadAppCTA";
import ErrorText from "@/components/ErrorText";
import FormField from "@/components/FormField";
import FormFields from "@/components/FormFields";
import Header from "@/components/Header";
import { Input } from "@/components/Input";
import Label from "@/components/Label";
import Link from "@/components/Link";
import Loader from "@/components/Loader";
import { PageRoute, PageTitle } from "@/constants/pages";
import { useRequestSignInCodeMutation, useSignInMutation } from "@/hooks/user";

const schema = z.object({
  email: z.string().min(1, { message: "Email is required" }).email({
    message: "Must be a valid email",
  }),
  password: z.string().min(1, { message: "Password is required" }),
});

type Schema = z.infer<typeof schema>;

export default function SignInPage() {
  const navigate = useNavigate();
  const { signInWithToken } = useAuth();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") ?? "";
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      email,
    },
  });
  const signInMutation = useSignInMutation();
  const requestSignInCodeMutation = useRequestSignInCodeMutation();

  const onSubmit = handleSubmit((data) => {
    signInMutation.mutate(data, {
      onSuccess: (res) => {
        signInWithToken(res);
      },
    });
  });

  useEffect(() => {
    if (!searchParams.get("email")) {
      navigate(PageRoute.AUTH);
    }
  }, [navigate, searchParams]);

  const handleSignInWithCode = () => {
    requestSignInCodeMutation.mutate(email, {
      onSuccess: () => {
        navigate(PageRoute.AUTH_SIGN_IN_CODE);
      },
    });
  };

  useDocumentTitle(PageTitle.AUTH_SIGN_IN);

  const isLoading =
    signInMutation.isPending || requestSignInCodeMutation.isPending;

  return (
    <>
      <form onSubmit={onSubmit} className="mb-8">
        <Header className="mb-10">Sign in</Header>

        <FormFields className="mb-4">
          <FormField>
            <div className="flex justify-between">
              <Label htmlFor="email">Email Address</Label>
              <Link
                as={RouterLink}
                to={{
                  pathname: PageRoute.AUTH,
                  search: new URLSearchParams({
                    email,
                  }).toString(),
                }}
              >
                Change
              </Link>
            </div>
            <Input id="email" type="email" disabled {...register("email")} />

            {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
          </FormField>
          <FormField>
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              disabled={signInMutation.isPending}
              {...register("password")}
            />
            {errors.password && (
              <ErrorText>{errors.password.message}</ErrorText>
            )}
          </FormField>

          {signInMutation.isError && <ErrorText error={signInMutation.error} />}

          {isLoading ? (
            <Loader className="mt-2" />
          ) : (
            <Button className="mt-2" type="submit" disabled={!isValid}>
              Sign in
            </Button>
          )}
        </FormFields>

        {!isLoading && (
          <>
            <div className="mb-6 flex flex-col gap-4">
              <p className="text-center">or</p>

              <Button
                type="button"
                onClick={handleSignInWithCode}
                variant="secondary"
                disabled={isLoading}
              >
                Sign in with Verification Code
              </Button>
            </div>

            <p className="mb-3 text-center">
              Have you forgotten your password?
            </p>
            <Link
              as={RouterLink}
              to={{
                pathname: PageRoute.AUTH_PASSWORD_RESET,
                search: new URLSearchParams({
                  email,
                }).toString(),
              }}
              className="block text-center"
            >
              Reset password
            </Link>
          </>
        )}
      </form>
      <DownloadAppCTA />
    </>
  );
}
