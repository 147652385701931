import { zodResolver } from "@hookform/resolvers/zod";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { StandardContent } from "@/components/App";
import { useAuth } from "@/components/Auth";
import Button, { IconButton } from "@/components/Button";
import ErrorText from "@/components/ErrorText";
import FormField from "@/components/FormField";
import FormFields from "@/components/FormFields";
import Header from "@/components/Header";
import Icon from "@/components/Icon";
import { Input } from "@/components/Input";
import Label from "@/components/Label";
import Loader from "@/components/Loader";
import { useNotification } from "@/components/Notification";
import { PageRoute, PageTitle } from "@/constants/pages";
import { usePageEventTracking } from "@/hooks/event-tracking";
import {
  useChangeEmailMutation,
  useRequestPasswordResetCodeMutation,
} from "@/hooks/user";

const schema = z.object({
  email: z.string().min(1, { message: "Email is required" }).email({
    message: "Must be a valid email",
  }),
  password: z.string().min(1, { message: "Password is required" }),
});

type Schema = z.infer<typeof schema>;

export default function AccountSecurityPage() {
  const [isShwoingEmailForm, setIsShowingEmailForm] = useState(false);
  const { user, logout } = useAuth();
  const notify = useNotification();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const changeEmailMutation = useChangeEmailMutation();

  const onSubmit = handleSubmit(({ email, password }) => {
    changeEmailMutation.mutate(
      { email_new: email, email_old: user?.email ?? "", password },
      {
        onSuccess: () => {
          reset();
          notify(`Email successfully updated`);
          setIsShowingEmailForm(false);
        },
      },
    );
  });

  const requestPasswordResetCodeMutation =
    useRequestPasswordResetCodeMutation();
  const navigate = useNavigate();

  usePageEventTracking();

  useDocumentTitle(PageTitle.ACCOUNT_SECURITY);

  return (
    <StandardContent>
      <Header className="mb-6">Account security</Header>

      <div className="flex items-center justify-between">
        <div className="mb-6 flex flex-col gap-1 text-sm">
          <div className="font-semibold">Email Address</div>
          <div className="text-amalfi-coast">{user?.email}</div>
        </div>
        {!isShwoingEmailForm && (
          <IconButton
            type="button"
            onClick={() => setIsShowingEmailForm(true)}
            aria-label="Edit email"
          >
            <Icon name="pencil" aria-hidden />
          </IconButton>
        )}
      </div>

      {isShwoingEmailForm && (
        <form onSubmit={onSubmit}>
          <FormFields className="mb-8">
            <FormField>
              <Label htmlFor="email">New Email</Label>
              <Input id="email" type="email" {...register("email")} />
              {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
            </FormField>
            <FormField>
              <Label htmlFor="password">Password</Label>
              <Input id="password" type="password" {...register("password")} />
              {errors.password && (
                <ErrorText>{errors.password.message}</ErrorText>
              )}
            </FormField>
            {changeEmailMutation.isError && (
              <ErrorText error={changeEmailMutation.error} />
            )}
            {changeEmailMutation.isPending ? (
              <Loader />
            ) : (
              <Button type="submit" disabled={!isValid}>
                Update email
              </Button>
            )}
          </FormFields>
        </form>
      )}

      {requestPasswordResetCodeMutation.isPending ? (
        <Loader />
      ) : (
        <div className="flex items-center justify-between">
          <div className="mb-6 flex flex-col gap-1 text-sm">
            <div className="font-semibold">Password</div>
            <div className="text-3xl ">••••••••</div>
          </div>

          <IconButton
            type="button"
            onClick={() =>
              requestPasswordResetCodeMutation.mutate(user?.email ?? "", {
                onSuccess: () => {
                  logout();
                  navigate({
                    pathname: PageRoute.AUTH_PASSWORD_RESET_SET,
                    search: new URLSearchParams({
                      email: user?.email ?? "",
                    }).toString(),
                  });
                },
              })
            }
            aria-label="Change password"
          >
            <Icon name="pencil" aria-hidden />
          </IconButton>
        </div>
      )}
    </StandardContent>
  );
}
