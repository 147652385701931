import { z } from "zod";

export const inprogressBookingSchema = z.object({
  serviceId: z.string(),
  date: z.string(),
  time: z.object({
    display_time: z.string(),
    appointment_datetime: z.string(),
  }),
});

export type InprogressBookingSchema = z.infer<typeof inprogressBookingSchema>;
