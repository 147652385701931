import { zodResolver } from "@hookform/resolvers/zod";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import Button from "@/components/Button";
import ErrorText from "@/components/ErrorText";
import FormField from "@/components/FormField";
import FormFields from "@/components/FormFields";
import Header from "@/components/Header";
import { Input } from "@/components/Input";
import Label from "@/components/Label";
import Loader from "@/components/Loader";
import { PageRoute, PageTitle } from "@/constants/pages";
import { useRequestPasswordResetCodeMutation } from "@/hooks/user";

const schema = z.object({
  email: z.string().min(1, { message: "Email is required" }).email({
    message: "Must be a valid email",
  }),
});

type Schema = z.infer<typeof schema>;

export default function PasswordResetPage() {
  const [searchParams] = useSearchParams();
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: searchParams.get("email") ?? "",
    },
  });
  const requestPasswordResetCodeMutation =
    useRequestPasswordResetCodeMutation();
  const navigate = useNavigate();

  const onSubmit = handleSubmit(({ email }) => {
    requestPasswordResetCodeMutation.mutate(email, {
      onSuccess: () => {
        navigate({
          pathname: PageRoute.AUTH_PASSWORD_RESET_SET,
          search: new URLSearchParams({
            email,
          }).toString(),
        });
      },
    });
  });

  useDocumentTitle(PageTitle.AUTH_PASSWORD_RESET);

  return (
    <form onSubmit={onSubmit}>
      <Header className="mb-10">Enter Your Email to Reset Your Password</Header>

      <FormFields>
        <FormField>
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            type="email"
            disabled={requestPasswordResetCodeMutation.isPending}
            {...register("email")}
          />
        </FormField>

        {requestPasswordResetCodeMutation.isError && (
          <ErrorText error={requestPasswordResetCodeMutation.error} />
        )}

        {requestPasswordResetCodeMutation.isPending ? (
          <Loader />
        ) : (
          <Button type="submit" disabled={!isValid}>
            Next
          </Button>
        )}
      </FormFields>
    </form>
  );
}
