import { zodResolver } from "@hookform/resolvers/zod";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { z } from "zod";

import { useAuth } from "@/components/Auth";
import Button from "@/components/Button";
import ErrorText from "@/components/ErrorText";
import FormField from "@/components/FormField";
import FormFields from "@/components/FormFields";
import Header from "@/components/Header";
import { Input } from "@/components/Input";
import Label from "@/components/Label";
import Link from "@/components/Link";
import Loader from "@/components/Loader";
import { useNotification } from "@/components/Notification";
import { PageRoute, PageTitle } from "@/constants/pages";
import { useSignInWithCodeMutation } from "@/hooks/user";

const schema = z.object({
  code: z.string().min(1, { message: "Code is required" }),
});

type Schema = z.infer<typeof schema>;

function SignInWithCodeErrorText({ error }: { error: unknown }) {
  if (error && error instanceof Error && error.message === "INVALID_CODE") {
    return (
      <ErrorText>
        We&apos;re sorry, the code entered doesn&apos;t match our records.
        Please verify using the code sent to your email address or{" "}
        <Link as={RouterLink} to={PageRoute.AUTH}>
          try requesting another code
        </Link>
        .
      </ErrorText>
    );
  }
  if (error && error instanceof Error && error.message === "CODE_USED") {
    return (
      <ErrorText>
        The code entered has already been used. You can{" "}
        <Link as={RouterLink} to={PageRoute.AUTH}>
          try requesting another code
        </Link>
        .
      </ErrorText>
    );
  }
  return <ErrorText error={error} />;
}

export default function SignInWithCodePage() {
  const [searchParams] = useSearchParams();
  const showNotification = useNotification();
  const { signInWithToken } = useAuth();
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      code: searchParams.get("code") ?? "",
    },
  });

  const signInWithCodeMutation = useSignInWithCodeMutation();

  const onSubmit = handleSubmit(({ code }) => {
    signInWithCodeMutation.mutate(code, {
      onSuccess: (res) => {
        showNotification(`You are now signed in`);
        signInWithToken(res);
      },
    });
  });

  useEffect(() => {
    if (searchParams.get("code")) {
      void onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useDocumentTitle(PageTitle.AUTH_SIGN_IN_WITH_CODE);

  return (
    <form onSubmit={onSubmit}>
      <Header className="mb-8">Sign in with code</Header>
      <p className="mb-6">Please enter the verification code we emailed you.</p>
      <FormFields>
        <FormField>
          <Label htmlFor="code">Code</Label>
          <Input id="code" type="text" {...register("code")} />

          {errors.code && <ErrorText>{errors.code.message}</ErrorText>}
        </FormField>

        {signInWithCodeMutation.isError && (
          <SignInWithCodeErrorText error={signInWithCodeMutation.error} />
        )}

        {signInWithCodeMutation.isPending ? (
          <Loader />
        ) : (
          <Button type="submit" disabled={!isValid}>
            Next
          </Button>
        )}
      </FormFields>
    </form>
  );
}
