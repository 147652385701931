import { useDocumentTitle } from "@uidotdev/usehooks";
import { Navigate, NavLink, useParams } from "react-router-dom";

import Button from "@/components/Button";
import Header from "@/components/Header";
import Loader from "@/components/Loader";
import { PageRoute, PageTitle } from "@/constants/pages";
import { useAppointmentQuery } from "@/hooks/appointments";
import { usePageEventTracking } from "@/hooks/event-tracking";

export default function AppointmentCanceledPage() {
  const { id } = useParams();
  const appointmentQuery = useAppointmentQuery(id);

  usePageEventTracking({
    service_name: appointmentQuery.data?.service_description,
    service_date: appointmentQuery.data?.start_datetime,
  });

  useDocumentTitle(
    PageTitle.APPOINTMENT_CANCELED(
      appointmentQuery.data?.service_description ?? "",
    ),
  );

  if (!id) {
    return <Navigate to={PageRoute.HOME} />;
  }

  if (!appointmentQuery.isSuccess) {
    return <Loader />;
  }

  return (
    <>
      <Header className="mb-6">Cancellation confirmed</Header>

      <p className="mb-8">Your appointment has been successfully cancelled.</p>

      <div className="mb-4 flex flex-col gap-4">
        <Button as={NavLink} to={PageRoute.APPOINTMENT_BOOK}>
          Book another appointment
        </Button>
        <Button as={NavLink} to={PageRoute.HOME} variant="secondary">
          Return to home
        </Button>
      </div>
    </>
  );
}
