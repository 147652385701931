import { formatISO } from "date-fns";

import { fetchJSONFactory } from "@/utils/fetch-json";

const fetchJSON = fetchJSONFactory(import.meta.env.VITE_USERS_API);

type SignInData = {
  email: string;
  password: string;
};

export type SignInResponse = {
  token: string;
  missingRequiredFields: boolean;
};

export async function signIn(data: SignInData) {
  try {
    return await fetchJSON<SignInResponse>("users/signin", {
      body: data,
      method: "POST",
    });
  } catch (error) {
    if (error instanceof Error && error.message === "INVALID_PASSWORD") {
      throw new Error(
        "The password you entered is incorrect. Please try again.",
      );
    }

    throw error;
  }
}

export async function signInWithCode(code: string) {
  try {
    return await fetchJSON<SignInResponse>("users/signin_code", {
      body: { code },
      method: "POST",
    });
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === "INVALID_CODE") {
        throw new Error("INVALID_CODE");
      }
      if (error.message === "CODE_USED") {
        throw new Error("CODE_USED");
      }
    }

    throw error;
  }
}

type SignUpData = {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: Date;
  password: string;
  marketingEmails: boolean;
};

export async function signUp(data: SignUpData) {
  const { firstName, lastName, email, dateOfBirth, password, marketingEmails } =
    data;

  try {
    return await fetchJSON<SignInResponse>("users/signup", {
      body: {
        first_name: firstName,
        last_name: lastName,
        email,
        dob: formatISO(dateOfBirth),
        password,
        origin: "consumer-web",
        allow_marketing_email: marketingEmails,
      },
      method: "POST",
    });
  } catch (error) {
    if (error instanceof Error && error.message === "USER_EXISTS") {
      throw new Error("User already exists");
    }

    throw error;
  }
}

export type RequiredFieldsData = {
  first_name: string;
  last_name: string;
  dob: string;
  allow_marketing_email: boolean;
};

export async function updateRequiredFields(data: RequiredFieldsData) {
  const { first_name, last_name, dob, allow_marketing_email } = data;

  try {
    return await fetchJSON<SignInResponse>("users/info", {
      body: {
        first_name: first_name,
        last_name: last_name,
        dob: formatISO(dob),
        origin: "consumer-web",
        allow_marketing_email: allow_marketing_email,
      },
      method: "POST",
    });
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === "INVALID_DOB") {
        throw new Error(
          "Sorry, we currently don't support online accounts for customers under 13. Please see or call your local Restore staff for assistance with your account creation in person",
        );
      }
    }

    throw error;
  }
}

export type EmailStatusResponse = {
  customer: {
    guid: string;
    first_name: string;
    last_name: string;
  };
  has_password: boolean;
  has_multiple_customers: boolean;
};

export function emailStatus(email: string) {
  return fetchJSON<EmailStatusResponse>(`users/email_status`, {
    params: { email },
  });
}

export type RequestPasswordResetCodeResponse = {
  success: true;
};

export async function requestPasswordResetCode(email: string) {
  try {
    const response = await emailStatus(email);

    return await fetchJSON<RequestPasswordResetCodeResponse>(
      `users/request_password_reset_code`,
      {
        body: {
          customer_guid: response.customer.guid,
        },
        method: "POST",
      },
    );
  } catch (error) {
    if (error instanceof Error && error.message === "INVALID_EMAIL") {
      throw new Error(
        "Sorry, we don’t see this email in our records. Please try a different email or sign up for a new account",
      );
    }

    throw error;
  }
}

type ChangePasswordData = {
  code: string;
  password: string;
};

export async function changePassword(data: ChangePasswordData) {
  try {
    return await fetchJSON<SignInResponse>(`users/change_password_from_code`, {
      body: data,
      method: "POST",
    });
  } catch (error) {
    if (error instanceof Error && error.message === "CODE_USED") {
      throw new Error("Code has already been used");
    }

    throw error;
  }
}

type RequestSignInCodeResponse = {
  success: true;
};

export async function requestSignInCode(email: string) {
  const response = await emailStatus(email);

  return await fetchJSON<RequestSignInCodeResponse>(
    `users/request_signin_code`,
    {
      body: { customer_guid: response.customer.guid },
      method: "POST",
    },
  );
}

type ExchangeStaffClientOneTimeCodeResponse = {
  jwt: string;
  redirect_url: string | null;
  missingRequiredFields: boolean;
};

type ExchangeStaffClientOneTimeCodeParams = {
  code: string;
  signal: AbortSignal;
};

export function exchangeStaffClientOneTimeCode(
  params: ExchangeStaffClientOneTimeCodeParams,
) {
  return fetchJSON<ExchangeStaffClientOneTimeCodeResponse>(
    "auth/staff-client/token",
    {
      signal: params.signal,
      params: {
        code: params.code,
      },
      method: "POST",
    },
  );
}

type NotificationPreferences = {
  account: {
    email: boolean;
    text: boolean;
  };
  schedule: {
    email: boolean;
    text: boolean;
  };
  promo: {
    email: boolean;
    text: boolean;
  };
};

export type UserInfo = {
  email: string;
  address: string | null;
  city: string | null;
  state: string | null;
  postal_code: string | null;
  first_name: string;
  last_name: string;
  mobile_phone: string | null;
  gender: string | null;
  emergency_name: string | null;
  emergency_phone: string | null;
  country: string | null;
  guid: string;
  core_waiver_expires: string | null;
  has_valid_core_waiver: boolean;
  has_card_on_file: boolean;
  dob: string | null;
};

type UpdateUserData = Partial<UserInfo> & {
  dateOfBirth?: string;
  notification_preferences?: {
    promo: {
      email?: boolean;
      text?: boolean;
    };
  };
};

export type UserInfoResponse = UserInfo & {
  notification_preferences: NotificationPreferences;
};

export async function updateUser({ dateOfBirth, ...data }: UpdateUserData) {
  try {
    return await fetchJSON<UserInfoResponse>(`users/info`, {
      body: dateOfBirth
        ? {
            dob: dateOfBirth,
            ...data,
          }
        : data,
      method: "PATCH",
    });
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === "INVALID_EMERGENCY_PHONE") {
        throw new Error("Invalid emergency phone number");
      }
      if (error.message === "INVALID_DOB") {
        throw new Error(
          "Sorry, we currently don't support online accounts for customers under 13. Please see or call your local Restore staff for assistance with your account creation in person",
        );
      }
      if (error.message === "INVALID_MOBILE_PHONE") {
        throw new Error("Invalid phone number");
      }
    }

    throw error;
  }
}

export function fetchUser() {
  return fetchJSON<UserInfoResponse>(`users/info`);
}

type ChangeEmailData = {
  email_old: string;
  email_new: string;
  password: string;
};

export async function changeEmail(data: ChangeEmailData) {
  try {
    return await fetchJSON<UserInfoResponse>(`users/change_email`, {
      body: data,
      method: "POST",
    });
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === "INVALID_PASSWORD") {
        throw new Error(
          "The password you entered is incorrect. Please try again.",
        );
      } else if (error.message === "USER_EXISTS") {
        throw new Error("That email is already in use. Please try again.");
      }
    }

    throw error;
  }
}

type UserDeletionRequestResponse = {
  date_requested: string;
  customer_guid: string;
  has_account_balance: boolean;
  has_active_membership: boolean;
  can_submit_delete_request: boolean;
};

export function fetchDeletionRequest() {
  return fetchJSON<UserDeletionRequestResponse>(`users/deletion_request`);
}

export function createDeletionRequest(password: string) {
  return fetchJSON<UserDeletionRequestResponse>(`users/deletion_request`, {
    body: { password },
    method: "POST",
  }).catch((error) => {
    if (error.message === "INVALID_PASSWORD") {
      throw new Error(
        "The password you entered is incorrect. Please try again.",
      );
    } else if (error.message === "DELETION_REQUEST_ALREADY_EXISTS") {
      throw new Error("You have already requested to delete your account");
    }

    throw error;
  });
}

export function saveCampaignQuery(query: string) {
  return fetchJSON<{ success: true }>(`users/campaigns/saw`, {
    body: { query },
    method: "POST",
  });
}

export function identifyAnonUser(guid: string) {
  return fetchJSON<{ success: true }>(`users/identify-anon-user`, {
    body: { guid },
    method: "POST",
  });
}
