import { sha256 } from "js-sha256";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAuth } from "@/components/Auth";

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

type PageEventPayload = {
  event: "pageview";
  page: {
    url: string;
  };
  studio_code?: string;
  user_id?: string;
  user_data?: {
    sha256_email_address: string;
    sha256_phone_number?: string | null;
  };
  service_name?: string;
  service_date?: string;
  service_time?: string;
};

export function usePageEventTracking(
  additionalPayload?: Partial<PageEventPayload>,
) {
  const { user, guid } = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    let payload: PageEventPayload = {
      event: "pageview",
      page: {
        url: pathname,
      },
      studio_code: localStorage.getItem("store") ?? undefined,
    };

    if (guid) {
      payload.user_id = guid;
    }

    if (user) {
      payload.user_data = {
        sha256_email_address: sha256(user.email),
        sha256_phone_number:
          user.mobile_phone && sha256(`+1${user.mobile_phone}`),
      };
    }

    if (additionalPayload) {
      payload = {
        ...payload,
        ...additionalPayload,
      };
    }

    const handler = setTimeout(() => {
      if (import.meta.env.VITE_GOOGLE_TAG_MANAGER) {
        window.dataLayer.push(payload);
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [pathname, user, guid, additionalPayload]);
}
