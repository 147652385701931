import { useClickAway } from "@uidotdev/usehooks";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { useAuth } from "@/components/Auth";
import DownloadAppCTA from "@/components/DownloadAppCTA";
import Header from "@/components/Header";
import Link from "@/components/Link";
import { useNotification } from "@/components/Notification";
import { ExternalLinks } from "@/constants/links";
import { PageRoute } from "@/constants/pages";

import { useFeatures } from "../Features";
import Icon from "../Icon";
import AlertIcon from "./alert.svg?react";
import CloseIcon from "./close.svg?react";
import MenuIcon from "./menu.svg?react";
import UserIcon from "./user.svg?react";

const Menu = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & {
    className?: string;
  }
>(({ className, ...props }, ref) => {
  return <nav ref={ref} className={twMerge("z-10", className)} {...props} />;
});

function MenuButton({
  className,
  isOpen,
  setIsOpen,
  ...rest
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
} & React.ComponentPropsWithoutRef<"button">) {
  return (
    <button
      className={twMerge(
        "text-amalfi-coast hover:text-studer-blue p-2 focus:outline-amalfi-coast",
        className,
      )}
      onClick={() => setIsOpen((s) => !s)}
      aria-expanded={isOpen ? "true" : "false"}
      {...rest}
    />
  );
}

function MenuDrawer({
  children,
  isOpen,
  side = "left",
}: {
  side?: "left" | "right";
  children: React.ReactNode;
  isOpen: boolean;
}) {
  return (
    <div
      className={twMerge(
        "fixed bg-white border-gray-300 top-0 max-w-lg w-full h-screen ease-in-out duration-300",
        isOpen ? "visible" : "invisible",
        isOpen && "translate-x-0",
        !isOpen && side === "left" && "-translate-x-full",
        side === "left" && "left-0",
        side === "left" && "border-r",
        !isOpen && side === "right" && "translate-x-full",
        side === "right" && "right-0",
        side === "right" && "border-l",
      )}
    >
      {children}
    </div>
  );
}

function MenuCloseButton({
  setIsOpen,
  ...rest
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>> &
    React.ComponentPropsWithoutRef<"button">;
}) {
  return (
    <button
      className="p-8 text-amalfi-coast hover:text-studer-blue focus:outline-amalfi-coast"
      onClick={() => setIsOpen((s) => !s)}
      aria-expanded="true"
      {...rest}
    >
      <CloseIcon width="14" aria-hidden />
    </button>
  );
}

function MenuLinks(props: React.HTMLProps<HTMLUListElement>) {
  return <ul className="mb-8 flex flex-col" {...props} />;
}

function MenuLink({
  className,
  ...props
}: React.ComponentProps<typeof Link> & {
  className?: string;
}) {
  return (
    <li className="flex">
      <Link
        className={twMerge(
          "text-left flex-1 p-4 hover:bg-child-of-light rounded-lg hover:text-cold-and-dark flex items-center",
          className,
        )}
        {...props}
      />
    </li>
  );
}

export function MobileMainMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useClickAway<HTMLElement>(() => setIsOpen(false));

  return (
    <Menu ref={ref} className={twMerge("mr-auto", isOpen ? "z-20" : "z-10")}>
      <MenuButton
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        aria-label="Open Main navigation"
      >
        <MenuIcon width="40" aria-hidden />
      </MenuButton>
      <MenuDrawer isOpen={isOpen}>
        <div className="flex justify-end">
          <MenuCloseButton
            setIsOpen={setIsOpen}
            aria-label="Close Main navigation"
          />
        </div>
        <div className="p-6">
          <MenuLinks onClick={() => setIsOpen(false)}>
            <MenuLink as={NavLink} to={PageRoute.HOME}>
              Home
            </MenuLink>
            <MenuLink as={NavLink} to={PageRoute.HOME}>
              Book
            </MenuLink>
            <MenuLink
              as="a"
              href={ExternalLinks.HOME}
              target="_blank"
              rel="noreferrer"
            >
              Restore.com
            </MenuLink>
          </MenuLinks>
          <DownloadAppCTA />
        </div>
      </MenuDrawer>
    </Menu>
  );
}

export function DesktopMainMenu() {
  const linkClassName =
    "flex items-center p-4 border-b-4 cursor-pointer font-semibold focus:outline-amalfi-coast";
  const inactiveClassName = "border-transparent text-amalfi-coast";
  const activeClassName = "border-amalfi-coast";

  return (
    <nav className="flex self-stretch">
      <ul className="flex gap-4">
        <li className="flex">
          <NavLink
            to={PageRoute.HOME}
            className={({ isActive }) =>
              twMerge(
                linkClassName,
                isActive ? activeClassName : inactiveClassName,
              )
            }
          >
            Home
          </NavLink>
        </li>
        <li className="flex">
          <NavLink
            to={PageRoute.APPOINTMENT_BOOK}
            className={({ isActive }) =>
              twMerge(
                linkClassName,
                isActive ? activeClassName : inactiveClassName,
              )
            }
          >
            Book
          </NavLink>
        </li>
        <li className="flex">
          <a
            href={ExternalLinks.HOME}
            target="_blank"
            rel="noreferrer"
            className={twMerge(linkClassName, inactiveClassName)}
          >
            Restore.com
          </a>
        </li>
      </ul>
    </nav>
  );
}

export function UserMenu() {
  const [showMore, setShowMore] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { logout, user, waiver, hasIncompletedAccountInformation } = useAuth();
  const ref = useClickAway<HTMLDivElement>(() => setIsOpen(false));
  const navigate = useNavigate();
  const notify = useNotification();
  const { isWellnessPlanEnabled } = useFeatures();

  const handleLogout = () => {
    logout();
    notify("You are now logged out");
    navigate(PageRoute.HOME);
  };

  const showAlert =
    hasIncompletedAccountInformation || waiver.state !== "VALID";

  return (
    <Menu className={twMerge("ml-auto", isOpen ? "z-20" : "z-10")} ref={ref}>
      <MenuButton
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className="relative"
        aria-label="Open Menu"
      >
        {showAlert && <AlertIcon className="absolute right-1 top-1" />}
        <UserIcon width="40" aria-hidden />
      </MenuButton>

      <MenuDrawer isOpen={isOpen} side="right">
        <MenuCloseButton setIsOpen={setIsOpen} aria-label="Close Menu" />

        <div className="p-6">
          {showMore ? (
            <>
              <button
                className="mb-8 flex items-center gap-2 px-4 font-poppins text-lg font-semibold text-studer-blue"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMore(false);
                }}
              >
                <Icon name="arrow" />
                More
              </button>

              <MenuLinks onClick={() => setIsOpen(false)}>
                {isWellnessPlanEnabled && (
                  <MenuLink as={NavLink} to={PageRoute.WELLNESS_PLAN_HISTORY}>
                    Wellness Plan History
                  </MenuLink>
                )}

                <MenuLink as={NavLink} to={PageRoute.ACCOUNT_DELETE}>
                  Delete Restore Account
                </MenuLink>
                <MenuLink as="a" href={ExternalLinks.TERMS} target="_blank">
                  Terms of Service
                </MenuLink>
                <MenuLink
                  as="a"
                  href={ExternalLinks.PRIVACY_POLICY}
                  target="_blank"
                >
                  Privacy Policy
                </MenuLink>
              </MenuLinks>
            </>
          ) : (
            <>
              <Header as="h3" className="mb-8 px-4 text-xl">
                Welcome {user?.first_name} {user?.last_name}!
              </Header>

              <MenuLinks onClick={() => setIsOpen(false)}>
                <MenuLink as={NavLink} to={PageRoute.ACCOUNT_PROFILE}>
                  Account Details and Waiver{" "}
                  {showAlert && <AlertIcon className="ml-auto" />}
                </MenuLink>
                <MenuLink as={NavLink} to={PageRoute.ACCOUNT_SECURITY}>
                  Account security
                </MenuLink>
                <MenuLink as={NavLink} to={PageRoute.ACCOUNT_PAYMENT_METHODS}>
                  Payment Methods
                </MenuLink>
                <MenuLink
                  as="button"
                  onClick={(e: Event) => {
                    e.stopPropagation();
                    setShowMore(true);
                  }}
                >
                  More
                </MenuLink>
                <MenuLink
                  as="button"
                  onClick={handleLogout}
                  className="text-sunset-orange"
                >
                  Logout
                </MenuLink>
              </MenuLinks>
            </>
          )}

          <DownloadAppCTA />
        </div>
      </MenuDrawer>
    </Menu>
  );
}
