import { zodResolver } from "@hookform/resolvers/zod";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { format, subYears } from "date-fns";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { z } from "zod";

import { useAuth } from "@/components/Auth";
import Button from "@/components/Button";
import DownloadAppCTA from "@/components/DownloadAppCTA";
import ErrorText from "@/components/ErrorText";
import FormField from "@/components/FormField";
import FormFields from "@/components/FormFields";
import Header from "@/components/Header";
import { Input } from "@/components/Input";
import Label from "@/components/Label";
import Link from "@/components/Link";
import Loader from "@/components/Loader";
import { useNotification } from "@/components/Notification";
import PasswordRequirements from "@/components/PasswordRequirements";
import { ExternalLinks } from "@/constants/links";
import { PageRoute, PageTitle } from "@/constants/pages";
import { useSignUpMutation } from "@/hooks/user";

const schema = z.object({
  firstName: z.string().min(1, { message: "First name is required" }),
  lastName: z.string().min(1, { message: "Last name is required" }),
  email: z.string().min(1, { message: "Email is required" }).email({
    message: "Must be a valid email",
  }),
  password: z
    .string()
    .min(8, { message: "Password must be at least 8 characters" })
    .regex(/[A-Z]/, "Password must contain a uppercase letter")
    .regex(/[a-z]/, "Password must contain a uppercase letter")
    .regex(/[0-9]/, "Password must contain a number")
    .regex(
      /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
      "Password must contain a special character (e.g. !@#$)",
    ),
  marketingEmails: z.boolean(),
  dateOfBirth: z.coerce.date().max(subYears(new Date(), 13), {
    message:
      "Sorry, we currently don't support online accounts for customers under 13. Please see or call your local Restore staff for assistance with your account creation in person",
  }),
});

type Schema = z.infer<typeof schema>;

const minDOB = format(subYears(new Date(), 120), "yyyy-MM-dd");
const maxDOB = format(new Date(), "yyyy-MM-dd");

export default function SignUpPage() {
  const { signInWithToken } = useAuth();
  const notify = useNotification();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") ?? "";
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    watch,
  } = useForm<Schema>({
    mode: "onBlur",
    resolver: zodResolver(schema),
    defaultValues: {
      email,
      marketingEmails: true,
    },
  });
  const signUpMutation = useSignUpMutation();

  const onSubmit = handleSubmit((data) => {
    signUpMutation.mutate(data, {
      onSuccess: (res) => {
        notify(`You are now signed up and signed in`);
        signInWithToken(res);
      },
    });
  });

  const password = watch("password");

  useDocumentTitle(PageTitle.AUTH_SIGN_UP);

  return (
    <>
      <form onSubmit={onSubmit} className="mb-8">
        <Header className="mb-10">Create a Restore Account</Header>

        <FormFields className="mb-4">
          <FormField>
            <div className="flex justify-between">
              <Label htmlFor="email">Email Address</Label>
              <Link
                as={RouterLink}
                to={{
                  pathname: PageRoute.AUTH,
                  search: new URLSearchParams({
                    email,
                  }).toString(),
                }}
              >
                Change
              </Link>
            </div>
            <Input
              id="email"
              disabled
              type="email"
              autoComplete="email"
              {...register("email")}
            />
            {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
          </FormField>

          <FormField>
            <Label htmlFor="firstName">First Name</Label>
            <Input id="firstName" type="text" {...register("firstName")} />
          </FormField>

          <FormField>
            <Label htmlFor="lastName">Last Name</Label>
            <Input id="lastName" type="text" {...register("lastName")} />
          </FormField>

          <FormField>
            <Label htmlFor="dateOfBirth">Date of Birth</Label>
            <Input
              id="dateOfBirth"
              type="date"
              min={minDOB}
              max={maxDOB}
              {...register("dateOfBirth")}
            />
            {errors.dateOfBirth && (
              <ErrorText>{errors.dateOfBirth.message}</ErrorText>
            )}
          </FormField>

          <FormField>
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              autoComplete="new-password"
              {...register("password")}
            />

            <PasswordRequirements password={password} />
          </FormField>

          <div className="flex items-start gap-2">
            <Input
              id="marketingEmails"
              type="checkbox"
              className="mt-2"
              {...register("marketingEmails")}
            />
            <label htmlFor="marketingEmails">
              I want to receive exclusive promotions emails from Restore Hyper
              Wellness. We respect your privacy and will never share your
              information with third parties without your permission.
            </label>
          </div>

          <p className="text-center">
            Please read our{" "}
            <Link href={ExternalLinks.TERMS} target="_blank">
              Terms of service
            </Link>{" "}
            and{" "}
            <Link href={ExternalLinks.PRIVACY_POLICY} target="_blank">
              Privacy Policy
            </Link>
          </p>

          {signUpMutation.isError && <ErrorText error={signUpMutation.error} />}

          {signUpMutation.isPending ? (
            <Loader />
          ) : (
            <Button type="submit" disabled={!isValid}>
              Sign up
            </Button>
          )}
        </FormFields>
      </form>
      <DownloadAppCTA />
    </>
  );
}
