import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";

type Options = {
  body?: Record<string, unknown>;
  params?: Record<string, string>;
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  baseUrl: string;
  signal?: AbortSignal;
};

async function fetchJSON<T>(path: string, options?: Options) {
  const method = options?.method ?? "GET";
  const token = Cookies.get("token");
  const baseUrl = options?.baseUrl;

  const params = options?.params
    ? new URLSearchParams(options?.params)
    : undefined;

  const response = await fetch(
    `${baseUrl}/${path}${params ? `?${params.toString()}` : ""}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: method,
      body: options?.body ? JSON.stringify(options.body) : undefined,
    },
  );

  if (response.ok) {
    const { data }: { data: T } = await response.json();

    return data;
  } else {
    const json: { error_code?: string } = await response.json();

    if (response.status > 401) {
      Sentry.captureException(new Error("API Error"), {
        extra: {
          url: `${baseUrl}/${path}`,
          response: json,
        },
      });
    }

    if (json.error_code) {
      throw new Error(json.error_code);
    }

    throw new Error();
  }
}

export function fetchJSONFactory(baseUrl: string) {
  return <T>(path: string, options?: Omit<Options, "baseUrl">) => {
    const o = options ? options : {};
    return fetchJSON<T>(path, { ...o, baseUrl });
  };
}
