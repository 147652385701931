import { useMutation, useQuery } from "@tanstack/react-query";

import {
  completeQuestionnaire,
  emailQuestionnaire,
  getPlan,
  getPlans,
  getQuestion,
  getQuestionnaireInfo,
  startQuestionnaire,
} from "@/services/forms";

const PLANS_QUERY_KEY = "plans";

const QUESTIONNARE_INFO_QUERY_KEY = "questionnare-info";

export const useStartQuestionnaireMutation = () =>
  useMutation({ mutationFn: startQuestionnaire });

export const useGetQuestionMutation = () =>
  useMutation({ mutationFn: getQuestion });

export const useCompleteQuestionnaireMutation = () =>
  useMutation({ mutationFn: completeQuestionnaire });

export const useGetQuestionnaireInfoQuery = (slug: string) =>
  useQuery({
    queryKey: [QUESTIONNARE_INFO_QUERY_KEY, slug],
    queryFn: () => getQuestionnaireInfo({ slug }),
  });

export const useEmailQuestionnaireMutation = () =>
  useMutation({ mutationFn: emailQuestionnaire });

export const useGetPlans = () =>
  useQuery({
    queryKey: [PLANS_QUERY_KEY],
    queryFn: getPlans,
    select: (data) =>
      [...data].sort(
        (a, b) =>
          Date.parse(b.plan_confirmed_datetime) -
          Date.parse(a.plan_confirmed_datetime),
      ),
  });

type UseGetPlanParams = {
  slug: string;
  id: string;
};

export const useGetPlan = (params: UseGetPlanParams) =>
  useQuery({
    queryKey: [PLANS_QUERY_KEY, params],
    queryFn: () => getPlan(params),
  });
