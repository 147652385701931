import { useDocumentTitle } from "@uidotdev/usehooks";
import { addDays, format, isValid, parse, subDays } from "date-fns";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Button from "@/components/Button";
import FormField from "@/components/FormField";
import FormFields from "@/components/FormFields";
import Header from "@/components/Header";
import Icon from "@/components/Icon";
import { Input, Select } from "@/components/Input";
import Label from "@/components/Label";
import Link from "@/components/Link";
import Loader from "@/components/Loader";
import { useNotification } from "@/components/Notification";
import { ExternalLinks } from "@/constants/links";
import { PageRoute, PageTitle } from "@/constants/pages";
import { useServicesQuery } from "@/hooks/booking";
import { usePageEventTracking } from "@/hooks/event-tracking";
import type { Store } from "@/services/booking";

import AvailableTimesPicker from "./AvailableTimesPicker";
import { InprogressBookingSchema, inprogressBookingSchema } from "./types";

type Props = {
  store: Store;
};

export default function BookingDetailsPage({ store }: Props) {
  const notify = useNotification();
  const location = useLocation();
  const inprogressBooking = useMemo(() => {
    const result = inprogressBookingSchema.safeParse(location.state);

    if (result.success) {
      return result.data;
    }
  }, [location.state]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const today = format(new Date(), "yyyy-MM-dd");
  const { register, watch, resetField, handleSubmit, setValue, control } =
    useForm<InprogressBookingSchema>({
      defaultValues: inprogressBooking ?? {
        date: today,
      },
    });

  const onSubmit = handleSubmit((data) => {
    navigate(PageRoute.APPOINTMENT_BOOK, { state: data, replace: true });
    navigate("/book/confirm", { state: data });
  });
  const servicesQuery = useServicesQuery(store.store_code);
  const serviceId = watch("serviceId");
  const dateValue = watch("date");
  const date = isValid(parse(dateValue, "yyyy-MM-dd", new Date()))
    ? dateValue
    : null;

  const time = watch("time");

  useEffect(() => {
    if (servicesQuery.data) {
      const serviceId = searchParams.get("service");

      if (serviceId) {
        if (
          servicesQuery.data.some((service) =>
            service.services.some((s) => String(s.id) === serviceId),
          )
        ) {
          setValue("serviceId", serviceId);
        } else {
          notify(
            "Sorry, that service is not available at this location. Please pick another service or select a different studio.",
            "error",
          );
        }
      }
    }
  }, [servicesQuery.data, searchParams, setValue, notify]);

  useEffect(() => {
    const subscription = watch((_values, { name }) => {
      if (name === "date" || name === "serviceId") {
        resetField("time");
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, resetField]);

  usePageEventTracking();

  useDocumentTitle(PageTitle.APPOINTMENT_BOOK_DETAILS);

  return (
    <form onSubmit={onSubmit}>
      <Header className="mb-2">
        Book an Appointment at {store.description}
      </Header>

      <p className="mb-6">
        <Link href={ExternalLinks.LOCATIONS}>Change studio</Link>
      </p>

      <FormFields>
        {servicesQuery.isLoading ? (
          <Loader />
        ) : (
          <FormField>
            <Label htmlFor="appointment-type">
              What service would you like?
            </Label>
            {servicesQuery.data?.length ? (
              <Select id="appointment-type" {...register("serviceId")}>
                <option></option>
                {servicesQuery.data.map((service) => (
                  <optgroup key={service.category} label={service.category}>
                    {service.services.map((service) => (
                      <option key={service.id} value={service.id}>
                        {service.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Select>
            ) : (
              <p className="text-sm italic text-gray-500">
                Sorry, we couldn&apos;t find any available services.
              </p>
            )}
          </FormField>
        )}

        {serviceId && (
          <FormField className="mb-4">
            <Label htmlFor="date">Date</Label>
            <Input id="date" type="date" min={today} {...register("date")} />
          </FormField>
        )}

        {date && serviceId && (
          <FormField className="mb-6">
            <div className="flex items-center justify-center gap-5 text-studer-blue">
              <button
                type="button"
                onClick={() => {
                  if (date === today) {
                    return;
                  }
                  setValue(
                    "date",
                    format(
                      subDays(parse(date, "yyyy-MM-dd", new Date()), 1),
                      "yyyy-MM-dd",
                    ),
                  );
                }}
                aria-label="Previous day"
                aria-disabled={date === today}
                className="p-4 focus:outline-amalfi-coast aria-disabled:cursor-not-allowed aria-disabled:text-gray-300"
              >
                <Icon name="arrow" aria-hidden />
              </button>
              <div className="text-xl font-semibold">
                {format(parse(date, "yyyy-MM-dd", new Date()), "MM/dd/yyyy")}
              </div>
              <button
                type="button"
                onClick={() =>
                  setValue(
                    "date",
                    format(
                      addDays(parse(date, "yyyy-MM-dd", new Date()), 1),
                      "yyyy-MM-dd",
                    ),
                  )
                }
                className="p-4 focus:outline-amalfi-coast"
                aria-label="Next day"
              >
                <Icon name="arrow" className="rotate-180" aria-hidden />
              </button>
            </div>

            <Controller
              control={control}
              name="time"
              render={({ field: { name, onChange, value } }) => (
                <AvailableTimesPicker
                  time={value}
                  storeCode={store.store_code}
                  serviceId={serviceId ?? undefined}
                  date={date ?? undefined}
                  name={name}
                  onChange={onChange}
                />
              )}
            />
          </FormField>
        )}

        {serviceId && date && time && <Button type="submit">Next</Button>}
      </FormFields>
    </form>
  );
}
