export const parseIntFallback = (fallback: number, str?: string): number => {
  if (!str) return fallback;

  const t = parseInt(str);

  if (Number.isNaN(t)) return fallback;

  return t;
};

/**
 * shorthand to check for null and undefined and smart cast to `T`.
 * @param value
 */
export const isSet = <T>(value: T | null | undefined): value is T => {
  return value !== null && value !== undefined;
};

export const removeWhitespace = (str: string) => {
  return str.replace(/\s/g, "");
};

export const isEmpty = (str: string): str is string => {
  return removeWhitespace(str).length === 0;
};

export const isNotEmpty = (str: string): str is string => {
  return removeWhitespace(str).length > 0;
};

export const isSetAndNotEmpty = (
  str: string | null | undefined,
): str is string => {
  return isSet(str) && isNotEmpty(str);
};
