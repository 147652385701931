import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import Button from "@/components/Button";
import Header from "@/components/Header";
import type { Question } from "@/services/forms";

const schema = z.object({
  answerSlug: z.string(),
});

type Schema = z.infer<typeof schema>;

type Props = {
  previousAnswer?: string;
  current?: number;
  total?: number;
  question: Question;
  onAnswerQuestion: (questionSlug: string, answerSlug: string) => void;
  onGoToPrevious?: (questionSlug: string) => void;
};

export default function WellnessPlanQuestionPage({
  total,
  current,
  question,
  onAnswerQuestion,
  onGoToPrevious,
  previousAnswer,
}: Props) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      answerSlug: previousAnswer,
    },
  });

  const onSubmit = handleSubmit(({ answerSlug }) => {
    onAnswerQuestion(question.slug, answerSlug);
  });

  return (
    <form onSubmit={onSubmit} className="self-stretch">
      <Header className="mb-5 border-b border-black/40 pb-5 text-4xl text-amalfi-coast">
        {question.text}
      </Header>

      <p className="mb-4">
        Choose the option that best fits your wellness goal.
      </p>

      <ul className="mb-6 grid gap-2">
        {question.answers.map((answer) => (
          <li key={answer.slug}>
            <label className="relative">
              <input
                {...register("answerSlug", { required: true })}
                type="radio"
                className="peer absolute opacity-0"
                value={answer.slug}
              />
              <div className="cursor-pointer rounded-lg border border-amalfi-coast bg-white p-3 font-semibold text-amalfi-coast peer-checked:border-2 peer-checked:text-cold-and-dark peer-focus:outline peer-focus:outline-amalfi-coast">
                {answer.text}
              </div>
            </label>
          </li>
        ))}
      </ul>

      <div className="grid items-center justify-center gap-2 lg:grid-cols-3 ">
        {current && total && (
          <p className="text-center lg:col-start-2">
            Question {current} of {total}
          </p>
        )}

        <Button
          type="submit"
          className="lg:justify-self-end"
          disabled={!isValid}
        >
          {current && total && current === total
            ? "Get My Wellness Plan"
            : "Confirm"}
        </Button>

        {onGoToPrevious && (
          <Button
            type="button"
            onClick={() => onGoToPrevious(question.slug)}
            variant="secondary"
            className="lg:col-start-1 lg:row-start-1 lg:justify-self-start"
          >
            Previous
          </Button>
        )}
      </div>
    </form>
  );
}
