import { Navigate, Route, Routes } from "react-router-dom";

import { StandardContent } from "@/components/App";
import Loader from "@/components/Loader";
import { PageRoute } from "@/constants/pages";
import { useStoreQuery } from "@/hooks/stores";

import BookingConfirmPage from "./Confirm";
import BookingDetailsPage from "./Details";
import BookingSuccessPage from "./Success";

function Booking() {
  const storeQuery = useStoreQuery();

  if (storeQuery.isLoading || !storeQuery.data) {
    return <Loader />;
  }

  return (
    <>
      <Routes>
        <Route path="success" element={<BookingSuccessPage />} />
        <Route
          path="confirm"
          element={<BookingConfirmPage store={storeQuery.data} />}
        />
        <Route path="*" element={<Navigate to={PageRoute.HOME} />} />
        <Route index element={<BookingDetailsPage store={storeQuery.data} />} />
      </Routes>
    </>
  );
}

export default function BookingPage() {
  return (
    <StandardContent>
      <Booking />
    </StandardContent>
  );
}
