import React from "react";
import { twMerge } from "tailwind-merge";

type Props = React.HTMLProps<HTMLDivElement> & {
  className?: string;
};

const FormField = React.forwardRef<HTMLDivElement, Props>(
  ({ className, ...rest }: Props, ref) => {
    return (
      <div
        className={twMerge("flex gap-1 flex-col", className)}
        {...rest}
        ref={ref}
      />
    );
  },
);

export default FormField;
