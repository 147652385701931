import { useEffect, useMemo, useState } from "react";

import Loader from "@/components/Loader";
import { useAvailableTimesQuery } from "@/hooks/booking";

type Props = {
  date?: string;
  storeCode: string;
  name: string;
  time: {
    display_time: string;
    appointment_datetime: string;
  } | null;
  serviceId?: string;
  onChange: (time: {
    display_time: string;
    appointment_datetime: string;
  }) => void;
};

export default function AvailableTimesPicker({
  serviceId,
  date,
  storeCode,
  name,
  onChange,
  time,
}: Props) {
  const [seletedTimeGroupIndex, setSelectedTimeGroupIndex] = useState(0);
  const availableTimesQuery = useAvailableTimesQuery({
    storeCode,
    serviceId,
    date,
  });

  const groups = useMemo(() => {
    if (
      !availableTimesQuery.data ||
      availableTimesQuery.data.times.length < 6
    ) {
      return;
    }

    const g = availableTimesQuery.data.times.reduce((acc, time) => {
      if (!acc.includes(time.group)) {
        acc.push(time.group);
      }

      return acc;
    }, [] as string[]);

    if (g.length > 1) {
      return g;
    }
  }, [availableTimesQuery.data]);

  const times = useMemo(() => {
    if (!availableTimesQuery.data) {
      return [];
    }

    if (!groups) {
      return availableTimesQuery.data.times;
    }

    return availableTimesQuery.data.times.filter(
      (time) => time.group === groups[seletedTimeGroupIndex],
    );
  }, [availableTimesQuery.data, groups, seletedTimeGroupIndex]);

  useEffect(() => {
    if (!groups?.[seletedTimeGroupIndex]) {
      setSelectedTimeGroupIndex(0);
    }
  }, [groups, seletedTimeGroupIndex]);

  useEffect(() => {
    if (availableTimesQuery.data && groups) {
      if (time) {
        const selectedTime = availableTimesQuery.data.times.find(
          (t) => t.appointment_datetime === time.appointment_datetime,
        );

        if (selectedTime) {
          const groupIndex = groups.findIndex((g) => g === selectedTime.group);
          setSelectedTimeGroupIndex(groupIndex);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableTimesQuery.data]);

  return (
    <>
      {groups && (
        <div
          role="tablist"
          className="mb-3 flex gap-4 border-b border-amalfi-coast/20"
        >
          {groups.map((group, index) => (
            <button
              key={group}
              type="button"
              role="tab"
              onClick={() => setSelectedTimeGroupIndex(index)}
              aria-selected={seletedTimeGroupIndex === index}
              className="flex-1 border-b-4 border-transparent p-4 text-sm focus:outline-amalfi-coast aria-selected:border-amalfi-coast aria-selected:font-semibold"
            >
              {group}
            </button>
          ))}
        </div>
      )}

      {availableTimesQuery.isLoading ? (
        <Loader />
      ) : times.length ? (
        <div className="grid grid-cols-3 gap-4">
          {times.map((t) => (
            <label key={t.appointment_datetime} className="relative">
              <input
                checked={time?.appointment_datetime === t.appointment_datetime}
                name={name}
                type="radio"
                className="peer absolute opacity-0"
                value={t.appointment_datetime}
                onChange={() => onChange(t)}
              />
              <div className="cursor-pointer rounded-lg border border-amalfi-coast p-3 text-center font-semibold text-amalfi-coast peer-checked:bg-child-of-light peer-checked:text-cold-and-dark peer-focus:outline peer-focus:outline-amalfi-coast">
                {t.display_time}
              </div>
            </label>
          ))}
        </div>
      ) : (
        <p className="text-center">No times found.</p>
      )}
    </>
  );
}
