import { createContext, useContext } from "react";

import { useFeaturesQuery } from "@/hooks/booking";

const FeaturesContext = createContext(
  {} as {
    isWellnessPlanEnabled?: boolean;
  },
);

export function FeaturesProvider({ children }: { children: JSX.Element }) {
  const featuresQuery = useFeaturesQuery();

  return (
    <FeaturesContext.Provider
      value={{ isWellnessPlanEnabled: featuresQuery.data?.wellnessPlan }}
    >
      {children}
    </FeaturesContext.Provider>
  );
}

export function useFeatures() {
  return useContext(FeaturesContext);
}
