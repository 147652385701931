import { zodResolver } from "@hookform/resolvers/zod";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { format } from "date-fns";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";

import { useAuth } from "@/components/Auth";
import Button from "@/components/Button";
import ErrorText from "@/components/ErrorText";
import FormField from "@/components/FormField";
import FormFields from "@/components/FormFields";
import Header from "@/components/Header";
import Icon from "@/components/Icon";
import { Input, Textarea } from "@/components/Input";
import Label from "@/components/Label";
import Link from "@/components/Link";
import Loader from "@/components/Loader";
import { useNotification } from "@/components/Notification";
import { AddCardForm } from "@/components/Stripe";
import { ExternalLinks } from "@/constants/links";
import { PageRoute, PageTitle } from "@/constants/pages";
import { useBookAppointmentMutation, useServicesQuery } from "@/hooks/booking";
import { usePageEventTracking } from "@/hooks/event-tracking";
import { useSetUserHasCardOnFile, useUpdateUserMutation } from "@/hooks/user";
import type { Store } from "@/services/booking";

import { inprogressBookingSchema } from "./types";

const schema = z.object({
  notes: z.string(),
  marketingTexts: z.boolean(),
  phone: z.string().min(10, {
    message: "10 digit phone number is required",
  }),
});

type Schema = z.infer<typeof schema>;

type Props = {
  store: Store;
};

export default function BookingConfirmPage({ store }: Props) {
  const location = useLocation();
  const inprogressBooking = useMemo(() => {
    const result = inprogressBookingSchema.safeParse(location.state);

    if (result.success) {
      return result.data;
    }
  }, [location.state]);
  const notify = useNotification();
  const { user } = useAuth();
  const updateUserMutation = useUpdateUserMutation();

  const bookAppointmentMutation = useBookAppointmentMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: { marketingTexts: true, phone: user?.mobile_phone ?? "" },
  });
  const setUserHasCardOnFile = useSetUserHasCardOnFile();
  const servicesQuery = useServicesQuery(store.store_code);
  const navigate = useNavigate();
  const isPhoneFieldsRequired = !user?.mobile_phone;

  const service = useMemo(() => {
    if (servicesQuery.data && inprogressBooking) {
      return servicesQuery.data
        .flatMap((appointmentType) => appointmentType.services)
        .find((service) => service.id === Number(inprogressBooking.serviceId));
    }
  }, [servicesQuery.data, inprogressBooking]);

  usePageEventTracking({
    service_name: service?.name,
    service_date: inprogressBooking?.time.appointment_datetime,
  });

  useDocumentTitle(PageTitle.APPOINTMENT_CONFIRM);

  if (!inprogressBooking) {
    return <Navigate to={PageRoute.APPOINTMENT_BOOK} />;
  }

  const isPaymentMethodRequired =
    !user?.has_card_on_file && service?.cancel_requires_payment;

  const onSubmit = handleSubmit(async ({ marketingTexts, phone, notes }) => {
    if (isPhoneFieldsRequired) {
      try {
        await updateUserMutation.mutateAsync({
          notification_preferences: {
            promo: {
              text: marketingTexts,
            },
          },
          mobile_phone: phone,
        });
      } catch (_error) {
        return;
      }
    }

    bookAppointmentMutation.mutate(
      {
        notes,
        storeCode: store.store_code,
        serviceId: inprogressBooking.serviceId,
        time: inprogressBooking.time.appointment_datetime,
      },
      {
        onSuccess: (response) => {
          navigate(
            {
              pathname: "/book/success",
            },
            {
              state: response,
            },
          );
        },
      },
    );
  });

  function handleAddCardSuccess() {
    notify("Card has been added");
    setUserHasCardOnFile();
  }

  if (!service) {
    return <Loader />;
  }

  return (
    <>
      <Header className="mb-6">Confirm your appointment details</Header>

      <div className="mb-6 flex flex-col gap-4 rounded-md bg-amalfi-coast/10 p-4 font-semibold text-amalfi-coast">
        <Link href={ExternalLinks.LOCATIONS} className="flex gap-2">
          <div className="flex w-[25px] items-center justify-center">
            <Icon name="map" />
          </div>
          {store.description}
        </Link>
        <Link
          onClick={() =>
            navigate(PageRoute.APPOINTMENT_BOOK, { state: inprogressBooking })
          }
          className="flex gap-2"
        >
          <div className="flex w-[25px] items-center justify-center">
            <Icon name="restore" />
          </div>
          {service.name}
        </Link>
        <Link
          onClick={() =>
            navigate(PageRoute.APPOINTMENT_BOOK, { state: inprogressBooking })
          }
          className="flex gap-2"
        >
          <div className="flex w-[25px] items-center justify-center">
            <Icon name="calendar" />
          </div>
          {format(new Date(inprogressBooking.time.appointment_datetime), "P")}
          <div className="ml-5 flex w-[25px] items-center justify-center">
            <Icon name="clock" />
          </div>
          {format(new Date(inprogressBooking.time.appointment_datetime), "p")}
        </Link>
      </div>

      {isPaymentMethodRequired ? (
        <>
          <Header className="mb-6 text-xl" as="h2">
            Late Cancellation
          </Header>

          <p className="mb-6">
            We require a valid credit or debit card on file for No Show and Late
            Cancellation fees within 24 hours of your appointment start time.
          </p>

          <div className="bg-linx-white p-4">
            <AddCardForm onSuccess={handleAddCardSuccess} />
          </div>
        </>
      ) : (
        <form onSubmit={onSubmit}>
          <FormFields className="mb-2">
            <FormField>
              <Label htmlFor="notes">Note for staff</Label>
              <Textarea rows={3} id="notes" {...register("notes")} />
            </FormField>
            {isPhoneFieldsRequired && (
              <FormField>
                <Label htmlFor="phone">Phone</Label>
                <Input id="phone" type="tel" {...register("phone")} />

                {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
              </FormField>
            )}

            {isPhoneFieldsRequired && (
              <FormField className="mb-2">
                <div className="flex items-start gap-2">
                  <Input
                    id="marketingTexts"
                    type="checkbox"
                    className="mt-1"
                    {...register("marketingTexts")}
                  />
                  <label htmlFor="marketingTexts" className="text-xs">
                    I consent to receive promotions and news from Restore Hyper
                    Wellness, including texts and calls. Reply “STOP” to opt out
                    at any time. Carrier rates may apply.
                  </label>
                </div>
              </FormField>
            )}

            {bookAppointmentMutation.isError && (
              <ErrorText error={bookAppointmentMutation.error} />
            )}

            {updateUserMutation.isError && (
              <ErrorText error={updateUserMutation.error} />
            )}

            {bookAppointmentMutation.isPending ||
            updateUserMutation.isPending ? (
              <Loader />
            ) : (
              <Button type="submit">Confirm booking</Button>
            )}
          </FormFields>

          <p className="text-center text-xs leading-5">
            {service.cancel_requires_payment && (
              <>
                This service is subject to No Show and Late Cancellation fees.
                <br />
              </>
            )}
            <>
              By confirming your appointment you agree to our <br />
              <Link href={ExternalLinks.TERMS} target="_blank">
                cancellation policy
              </Link>{" "}
              and{" "}
              <Link href={ExternalLinks.PRIVACY_POLICY} target="_blank">
                privacy policy
              </Link>
              .
            </>
          </p>
        </form>
      )}
    </>
  );
}
