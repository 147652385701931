import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  changeEmail,
  changePassword,
  createDeletionRequest,
  emailStatus,
  exchangeStaffClientOneTimeCode,
  fetchDeletionRequest,
  fetchUser,
  requestPasswordResetCode,
  requestSignInCode,
  signIn,
  signInWithCode,
  signUp,
  updateUser,
  type UserInfo,
} from "@/services/user";

const USER_QUERY_KEY = "user";

const DELETION_REQUEST_QUERY_KEY = "deletion-request";

export const useEmailStatusMutation = () =>
  useMutation({
    mutationFn: emailStatus,
  });

export const useRequestSignInCodeMutation = () =>
  useMutation({
    mutationFn: requestSignInCode,
  });

export const useRequestPasswordResetCodeMutation = () =>
  useMutation({
    mutationFn: requestPasswordResetCode,
  });

export const useSignInMutation = () => useMutation({ mutationFn: signIn });

export const useSignInWithCodeMutation = () =>
  useMutation({ mutationFn: signInWithCode });

export const useSignUpMutation = () => useMutation({ mutationFn: signUp });

export const useChangePasswordMutation = () =>
  useMutation({ mutationFn: changePassword });

export const useChangeEmailMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: changeEmail,
    onSuccess: (data) => {
      queryClient.setQueryData([USER_QUERY_KEY], data);
    },
  });
};

export const useUserQuery = (token?: string) =>
  useQuery({
    queryKey: [USER_QUERY_KEY],
    queryFn: fetchUser,
    enabled: Boolean(token),
  });

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateUser,
    onSuccess: (data) => {
      queryClient.setQueryData([USER_QUERY_KEY], data);
    },
  });
};

export const useCreateDeletionRequestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createDeletionRequest,
    onSuccess: (res) => {
      queryClient.setQueryData([DELETION_REQUEST_QUERY_KEY], res);
    },
  });
};

export const useDeletionRequestQuery = () =>
  useQuery({
    queryKey: [DELETION_REQUEST_QUERY_KEY],
    queryFn: fetchDeletionRequest,
  });

export const useSetUserHasCardOnFile = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.setQueryData<UserInfo>([USER_QUERY_KEY], (data) =>
      data
        ? {
            ...data,
            has_card_on_file: true,
          }
        : data,
    );
  };
};

export const useStaffClientOneTimeCodeMutation = () => {
  return useMutation({
    mutationFn: exchangeStaffClientOneTimeCode,
  });
};
