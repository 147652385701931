import { zodResolver } from "@hookform/resolvers/zod";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { format, subYears } from "date-fns";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { z } from "zod";

import { StandardContent } from "@/components/App";
import { useAuth } from "@/components/Auth";
import Button from "@/components/Button";
import FormField from "@/components/FormField";
import FormFields from "@/components/FormFields";
import Header from "@/components/Header";
import { Input } from "@/components/Input";
import Label from "@/components/Label";
import Link from "@/components/Link";
import Loader from "@/components/Loader";
import { useNotification } from "@/components/Notification";
import { ExternalLinks } from "@/constants/links";
import { PageTitle } from "@/constants/pages";
import { useUpdateUserMutation } from "@/hooks/user";
import { RequiredFieldsData, UserInfo } from "@/services/user";

const Schema = z.object({
  first_name: z.string().min(1, { message: "First name is required" }),
  last_name: z.string().min(1, { message: "Last name is required" }),
  dob: z.string().min(1, { message: "Date of Birth is required" }),
  allow_marketing_email: z.boolean().default(false),
});

const minDOB = format(subYears(new Date(), 120), "yyyy-MM-dd");
const maxDOB = format(new Date(), "yyyy-MM-dd");

type Schema = z.infer<typeof Schema>;

const RequiredInfoPage = () => {
  const { userQuery, onCompletedRequiredFields } = useAuth();

  if (userQuery.isLoading) {
    return <Loader />;
  } else if (!userQuery.data) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  const user = userQuery.data;

  return (
    <StandardContent>
      <Content
        user={user}
        onCompletedRequiredFields={onCompletedRequiredFields}
      />
    </StandardContent>
  );
};

type ContentProps = {
  user: UserInfo;
  onCompletedRequiredFields: () => void;
};

const Content = (props: ContentProps) => {
  const { user } = props;
  const notify = useNotification();

  const fn = user.first_name ?? "";
  const ln = user.last_name ?? "";
  const dob = user.dob ?? "";

  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm<Schema>({
    resolver: zodResolver(Schema),
    defaultValues: {
      first_name: fn,
      last_name: ln,
      dob: dob,
      allow_marketing_email: false,
    } satisfies RequiredFieldsData,
  });

  const updateUserMutation = useUpdateUserMutation();

  const onSubmit = handleSubmit((data) => {
    updateUserMutation.mutate(data, {
      onSuccess: () => {
        notify(`Your profile changes have been saved`);
        props.onCompletedRequiredFields();
      },
    });
  });

  useDocumentTitle(PageTitle.REQUIRED_INFO);

  return (
    <form onSubmit={onSubmit}>
      <Header className="mb-6">Complete your profile</Header>

      <FormFields>
        <FormField>
          <Label htmlFor="first_name">First Name</Label>
          <Input id="first_name" type="text" {...register("first_name")} />
        </FormField>

        <FormField>
          <Label htmlFor="last_name">Last Name</Label>
          <Input id="last_name" type="text" {...register("last_name")} />
        </FormField>

        <FormField>
          <Label htmlFor="dob">Date of Birth</Label>
          <Input
            id="dob"
            type="date"
            min={minDOB}
            max={maxDOB}
            {...register("dob")}
          />
        </FormField>

        <div className="flex items-start gap-2">
          <Input
            id="marketingEmails"
            type="checkbox"
            className="mt-2"
            {...register("allow_marketing_email")}
          />
          <label htmlFor="marketingEmails">
            I want to receive exclusive promotions emails from Restore Hyper
            Wellness. We respect your privacy and will never share your
            information with third parties without your permission.
          </label>
        </div>

        <p className="text-center">
          Please read our{" "}
          <Link href={ExternalLinks.TERMS} target="_blank">
            Terms of service
          </Link>{" "}
          and{" "}
          <Link href={ExternalLinks.PRIVACY_POLICY} target="_blank">
            Privacy Policy
          </Link>
        </p>

        {updateUserMutation.isPending ? (
          <Loader />
        ) : (
          <Button type="submit" disabled={!isValid}>
            Save
          </Button>
        )}
      </FormFields>
    </form>
  );
};

export default RequiredInfoPage;
