import { format, isToday } from "date-fns";
import { twMerge } from "tailwind-merge";

import Icon from "@/components/Icon";

type Props = {
  date: string;
  serviceName: string;
  storeName: string;
  className?: string;
};

export default function AppointmentCard({
  date,
  serviceName,
  storeName,
  className,
}: Props) {
  const startDateTime = new Date(date);
  const isAppointmentToday = isToday(startDateTime);

  return (
    <div
      className={twMerge(
        "rounded-md p-4 bg-amalfi-coast/10 flex flex-col gap-3",
        className,
      )}
    >
      <div className="flex items-center gap-2 font-semibold">
        {isAppointmentToday ? "TODAY" : format(startDateTime, "P")}
        <span className="font-normal"> at {format(startDateTime, "p")}</span>
        <Icon name="calendar" className="ml-auto" aria-hidden />
      </div>
      <div className="font-semibold">{serviceName}</div>
      <div className="text-sm">{storeName}</div>
    </div>
  );
}
