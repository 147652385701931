import { useDocumentTitle } from "@uidotdev/usehooks";
import { useMemo, useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import { useFeatures } from "@/components/Features";
import Loader from "@/components/Loader";
import { PageRoute, PageTitle } from "@/constants/pages";
import {
  useGetQuestionMutation,
  useGetQuestionnaireInfoQuery,
  useStartQuestionnaireMutation,
} from "@/hooks/wellness-plan";
import {
  Direction,
  GetQuestionResponseType,
  type PreviousAnswer,
} from "@/services/forms";

import WellnessPlanHistory from "./History";
import IntroPage from "./Intro";
import WellnessPlanPlanPage from "./Plan";
import QuestionPage from "./Question";
import WellnessPlanResultsPage from "./Results";

function WellnessPlan() {
  const { slug = "" } = useParams();
  const [previousAnswers, setPreviousAnswers] = useState<PreviousAnswer[]>([]);
  const startQuestionnaireMutation = useStartQuestionnaireMutation();
  const getQuestionMutation = useGetQuestionMutation();
  const getQuestionnaireInfoQuery = useGetQuestionnaireInfoQuery(slug);

  useDocumentTitle(PageTitle.WELLNESS_PLAN);

  const isLoading = useMemo(() => {
    return (
      startQuestionnaireMutation.isPending ||
      getQuestionMutation.isPending ||
      getQuestionnaireInfoQuery.isPending
    );
  }, [
    startQuestionnaireMutation.isPending,
    getQuestionMutation.isPending,
    getQuestionnaireInfoQuery.isPending,
  ]);

  const isError = useMemo(() => {
    return (
      startQuestionnaireMutation.isError ||
      getQuestionMutation.isError ||
      getQuestionnaireInfoQuery.isError
    );
  }, [
    startQuestionnaireMutation.isError,
    getQuestionMutation.isError,
    getQuestionnaireInfoQuery.isError,
  ]);

  const question = useMemo(() => {
    if (
      getQuestionMutation.data &&
      getQuestionMutation.data.type === GetQuestionResponseType.QUESTION
    ) {
      return getQuestionMutation.data.question;
    }
    if (startQuestionnaireMutation.data) {
      return startQuestionnaireMutation.data.question;
    }
  }, [startQuestionnaireMutation.data, getQuestionMutation.data]);

  const previousAnswer = useMemo(() => {
    if (!startQuestionnaireMutation.data || !question) {
      return;
    }

    const pas = [
      ...startQuestionnaireMutation.data.previousAnswers,
      ...previousAnswers,
    ];

    const pa = pas.find((p) => p.question === question.slug);

    if (pa) {
      return pa.answer;
    }
  }, [startQuestionnaireMutation.data, question, previousAnswers]);

  const data = useMemo(() => {
    if (getQuestionMutation.data && getQuestionMutation.data.type === "plan") {
      return {
        plan: getQuestionMutation.data.plan,
        promos: getQuestionMutation.data.promos,
      };
    }
  }, [getQuestionMutation.data]);

  const session = useMemo(() => {
    if (startQuestionnaireMutation.data) {
      return startQuestionnaireMutation.data.session;
    }
  }, [startQuestionnaireMutation.data]);

  const totalQuestions = useMemo(() => {
    if (
      getQuestionMutation.data &&
      getQuestionMutation.data.type === "question"
    ) {
      return getQuestionMutation.data.totalQuestions;
    }
    if (startQuestionnaireMutation.data) {
      return startQuestionnaireMutation.data.totalQuestions;
    }
  }, [startQuestionnaireMutation.data, getQuestionMutation.data]);

  const currentQuestion = useMemo(() => {
    if (
      getQuestionMutation.data &&
      getQuestionMutation.data.type === "question"
    ) {
      return getQuestionMutation.data.questionIndex;
    }
    if (startQuestionnaireMutation.data) {
      return startQuestionnaireMutation.data.questionIndex;
    }
  }, [startQuestionnaireMutation.data, getQuestionMutation.data]);

  const handleStart = () =>
    startQuestionnaireMutation.mutate({
      slug,
      reset: false,
    });

  const handleAnswerQuestion = (questionSlug: string, answerSlug: string) => {
    if (!session) {
      return;
    }

    getQuestionMutation.mutate(
      {
        session,
        question: questionSlug,
        direction: Direction.NEXT,
        answer: answerSlug,
      },
      {
        onSuccess: () => {
          setPreviousAnswers((s) => [
            ...s,
            {
              question: questionSlug,
              answer: answerSlug,
            },
          ]);
        },
      },
    );
  };

  const handleGoToPrevious = (questionSlug: string) => {
    if (!session) {
      return;
    }

    getQuestionMutation.mutate({
      session,
      question: questionSlug,
      direction: Direction.PREVIOUS,
    });
  };

  if (isError) {
    return <Navigate to={PageRoute.HOME} />;
  }

  if (isLoading) {
    return (
      <div className="flex flex-1 flex-col p-5 lg:px-16 lg:py-10">
        <Loader />
      </div>
    );
  }

  if (data) {
    return (
      <main className="flex flex-1 bg-child-of-light">
        <WellnessPlanPlanPage plan={data.plan} promos={data.promos} />
      </main>
    );
  }

  return (
    <main className="flex flex-1 flex-col bg-child-of-light lg:flex-row">
      <div className="h-[183px] bg-[url('/lifestyle.webp')] bg-cover bg-[center_top_-16vw] bg-no-repeat lg:h-auto lg:w-4/12 lg:bg-center"></div>
      <div className="flex flex-1 flex-col items-start p-5 lg:px-16 lg:py-10">
        {question ? (
          <QuestionPage
            question={question}
            previousAnswer={previousAnswer}
            total={totalQuestions}
            current={currentQuestion}
            onAnswerQuestion={handleAnswerQuestion}
            onGoToPrevious={
              currentQuestion && currentQuestion > 1
                ? handleGoToPrevious
                : undefined
            }
          />
        ) : (
          <IntroPage
            onStart={handleStart}
            headerDescription={
              getQuestionnaireInfoQuery.data?.headerDescription ?? ""
            }
            subHeaderDescription={
              getQuestionnaireInfoQuery.data?.subHeaderDescription ?? ""
            }
          />
        )}
      </div>
    </main>
  );
}

export default function WellnessPlanPage() {
  const { isWellnessPlanEnabled } = useFeatures();

  if (isWellnessPlanEnabled === undefined) {
    return (
      <div className="flex flex-1 flex-col p-5 lg:px-16 lg:py-10">
        <Loader />
      </div>
    );
  }

  if (isWellnessPlanEnabled === false) {
    return <Navigate to="/" />;
  }

  return (
    <Routes>
      <Route path=":id" element={<WellnessPlanResultsPage />} />
      <Route path="history" element={<WellnessPlanHistory />} />
      <Route path="start" element={<WellnessPlan />} />
      <Route index element={<Navigate to="start" />} />
    </Routes>
  );
}
