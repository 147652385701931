import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useAuth } from "@/components/Auth";
import { identifyAnonUser, saveCampaignQuery } from "@/services/user";
import { isSetAndNotEmpty } from "@/utils/helpers";

const CAMPAIGN_QUERY_COOKIE_KEY = "campaign-query";
const ANON_GUID_QUERY_KEY = "anon_id";

export function useCampaignTracking() {
  const [cookie, setCookie] = useState<string | undefined>(
    Cookies.get(CAMPAIGN_QUERY_COOKIE_KEY),
  );
  const [anonId, setAnonId] = useState<string | undefined>(undefined);
  const { isSignedIn } = useAuth();
  const saveCampaignQueryMutation = useMutation({
    mutationFn: saveCampaignQuery,
  });
  const identifyAnonUserMutation = useMutation({
    mutationFn: identifyAnonUser,
  });
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const queryParams = searchParams.toString();
    const anonIdQueryParam = searchParams.get(ANON_GUID_QUERY_KEY);

    if (queryParams.includes("utm") || queryParams.includes("promotion")) {
      Cookies.set(CAMPAIGN_QUERY_COOKIE_KEY, queryParams, {
        expires: 7,
        secure: true,
      });
      setCookie(queryParams);
    }

    if (isSetAndNotEmpty(anonIdQueryParam)) {
      setAnonId(anonIdQueryParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSignedIn) {
      if (isSetAndNotEmpty(cookie)) {
        saveCampaignQueryMutation.mutate(cookie, {
          onSuccess: () => {
            setCookie(undefined);
            Cookies.remove(CAMPAIGN_QUERY_COOKIE_KEY);
          },
        });
      }

      // handling both cases where potentially anonGuid AND utm/promotion params are provided
      if (isSetAndNotEmpty(anonId)) {
        identifyAnonUserMutation.mutate(anonId, {
          onSuccess: () => {
            setAnonId(undefined);
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookie, isSignedIn, anonId]);
}
