import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ExternalLinks } from "@/constants/links";
import { PageRoute } from "@/constants/pages";
import { cancelAppointment, fetchStore } from "@/services/booking";

const STORE_SEARCH_PARAM_KEY = "store";

export function useStoreQuery(code?: string) {
  const storeCode = code ?? localStorage.getItem("store");

  if (!storeCode) {
    document.location.href = ExternalLinks.LOCATIONS;
  }

  return useQuery({
    queryKey: ["stores", storeCode],
    queryFn: () => {
      if (storeCode) {
        return fetchStore(storeCode);
      }
    },
    enabled: Boolean(storeCode),
  });
}

export function usePersistedStore() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const store = searchParams.get(STORE_SEARCH_PARAM_KEY);

    if (store) {
      localStorage.setItem("store", store);
      searchParams.delete(STORE_SEARCH_PARAM_KEY);
      navigate(
        {
          pathname: PageRoute.APPOINTMENT_BOOK,
          search: searchParams.toString(),
        },
        {
          replace: true,
        },
      );
    }
  }, [navigate, searchParams]);
}

export const useCancelAppointmentMutation = () =>
  useMutation({
    mutationFn: cancelAppointment,
  });
