import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";

import { useAuth } from "@/components/Auth";
import Button from "@/components/Button";
import FormField from "@/components/FormField";
import FormFields from "@/components/FormFields";
import Header from "@/components/Header";
import { Input } from "@/components/Input";
import Label from "@/components/Label";
import Loader from "@/components/Loader";
import { PageRoute } from "@/constants/pages";
import {
  useCompleteQuestionnaireMutation,
  useEmailQuestionnaireMutation,
} from "@/hooks/wellness-plan";
import type { Plan, PlanPromo, PlanService } from "@/services/forms";
import { possessive } from "@/utils/possesive";

type Props = {
  plan: Plan;
  promos: PlanPromo[];
  isConfirmed?: boolean;
};

export default function WellnessPlanPlanPage({
  plan,
  isConfirmed,
  promos,
}: Props) {
  const { user } = useAuth();
  const completeQuestionnaireMutation = useCompleteQuestionnaireMutation();
  const { register, handleSubmit } = useForm<{ email: string }>({
    defaultValues: {
      email: user?.email,
    },
  });
  const emailQuestionnaireMutation = useEmailQuestionnaireMutation();

  const onSubmit = handleSubmit(({ email }) => {
    emailQuestionnaireMutation.mutate({ email, session: plan.session });
  });

  const handleComplete = () => {
    completeQuestionnaireMutation.mutate({
      session: plan.session,
    });
  };

  const planServices = useMemo(() => {
    return plan.services.reduce(
      (acc, service) => {
        acc[service.slug] = service;

        return acc;
      },
      {} as Record<string, PlanService>,
    );
  }, [plan]);

  return (
    <>
      <div className="flex flex-1 flex-col lg:flex-row">
        <div className="flex flex-1 flex-col p-5 lg:p-8">
          <Header className="mb-5 border-b border-black/40 pb-5 text-4xl">
            <span className="text-amalfi-coast">
              {user?.first_name} {possessive(user?.last_name ?? "")}
            </span>{" "}
            Wellness Plan
          </Header>

          <p className="mb-10">{plan.customerPlanHeader}</p>

          <ul className="mb-6 grid gap-12 pt-[35px] text-sm lg:grid-cols-3 lg:gap-5">
            {plan.services.map((service) => (
              <li
                key={service.slug}
                className="rounded-md border border-nordic-noir bg-white px-3 pb-3"
              >
                <header className="mb-5 mt-[-35px] flex flex-col items-center border-b-2 border-[#000]/40 pb-5">
                  <img
                    src={service.icon}
                    alt=""
                    className="mb-4 h-[70px] w-[70px]"
                  />
                  <h3 className="text-xl font-bold">{service.description}</h3>
                  <p className="">{service.frequency.times}</p>
                </header>

                <h4 className="font-semibold">What to expect</h4>

                <ul className="mb-5 list-disc pl-6">
                  {service.whatToExpect.map((expectation) => (
                    <li key={expectation}>{expectation}</li>
                  ))}
                </ul>

                <h4 className="font-semibold">Did you Know?</h4>
                <p>{service.didYouKnow}</p>
              </li>
            ))}
          </ul>

          <div className="mb-14 flex justify-center">
            {isConfirmed ?? completeQuestionnaireMutation.isSuccess ? (
              <p className="text-sm text-studer-blue">
                You have claimed this plan
              </p>
            ) : completeQuestionnaireMutation.isPending ? (
              <Loader />
            ) : (
              <Button type="button" onClick={() => handleComplete()}>
                Make This My Plan
              </Button>
            )}
          </div>

          <Header className="mb-5 text-3xl text-amalfi-coast">
            Sample Monthly Schedule
          </Header>

          <ul className="mb-10 grid grid-cols-2 gap-8 lg:grid-cols-4">
            {plan.weeks.map((week) => (
              <li key={week.num}>
                <h4 className="mb-4 text-lg font-semibold text-studer-blue">
                  Week {week.num}
                </h4>
                <ul className="flex flex-col gap-2">
                  {week.visits.map((visit) => (
                    <li key={visit.num}>
                      <h5 className="mb-2 text-sm uppercase">
                        Visit {visit.num}
                      </h5>
                      <ul className="mb-4 flex flex-col gap-1">
                        {visit.services.map((service) => (
                          <li
                            key={service}
                            className="flex items-center gap-2 rounded-md bg-white p-2"
                          >
                            <img
                              src={planServices[service].icon}
                              alt=""
                              className="h-[30px] w-[30px]"
                            />
                            {planServices[service].description}
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>

          <Button
            as={NavLink}
            className="mb-10 self-center"
            to={PageRoute.APPOINTMENT_BOOK}
          >
            Book an Appointment
          </Button>

          <p className="text-xs italic">
            * Not all services are available at all locations. Any medical
            service will be subject to consultation with an independent medical
            professional and the recommendation and its benefits are based upon
            clinical protocols established by that professional entity.
            Contraindications may limit or prevent certain services. You should
            discuss any questions or concerns with a healthcare professional.
          </p>
        </div>
        <div className="flex flex-col gap-3 lg:w-3/12 lg:p-5">
          <div className="flex flex-col bg-nordic-noir p-6 text-white">
            <h3 className="mb-3 text-2xl font-semibold text-amalfi-coast">
              Save your Wellness Plan
            </h3>

            {emailQuestionnaireMutation.isSuccess ? (
              <p>We have sent your personal wellness plan to your inbox.</p>
            ) : (
              <>
                <p className="mb-5">
                  Enter your email address, and we’ll send you your personalized
                  Wellness Plan.
                </p>
                <form onSubmit={onSubmit}>
                  <FormFields>
                    <FormField>
                      <Label>Email Address</Label>
                      <Input
                        id="email"
                        type="email"
                        autoComplete="email"
                        disabled
                        {...register("email")}
                      />
                    </FormField>
                    {emailQuestionnaireMutation.isPending ? (
                      <Loader />
                    ) : (
                      <Button type="submit" color="white" className="self-end">
                        Send me my plan
                      </Button>
                    )}
                  </FormFields>
                </form>
              </>
            )}
          </div>

          {promos.map((promo, index) => (
            <PromoItem promo={promo} key={index} />
          ))}
        </div>
      </div>
    </>
  );
}

const PromoItem = (props: { promo: PlanPromo }) => {
  const { promo } = props;

  const lines = useMemo(() => promo.body.split("\n"), [promo.body]);

  return (
    <div className="flex flex-col bg-nordic-noir p-6 text-white">
      <p className="mb-2 text-sm text-[#4492C0]">{promo.title}</p>

      {promo.subTitle ? (
        <h3 className="mb-3 text-2xl font-semibold">{promo.subTitle}</h3>
      ) : null}

      {lines.map((line, index) => (
        <p key={index}>{line}</p>
      ))}

      <Button
        as="a"
        href={promo.primaryCTA.link}
        color="white"
        className="mt-4 self-end"
      >
        {promo.primaryCTA.text}
      </Button>
    </div>
  );
};
