import { twMerge } from "tailwind-merge";

type Props<T extends React.ElementType> = React.ComponentPropsWithoutRef<T> & {
  as?: T;
  className?: string;
};

function Link<T extends React.ElementType>({
  as,
  className,
  ...rest
}: Props<T>) {
  const Component = as ?? "a";

  return (
    <Component
      className={twMerge(
        "cursor-pointer font-semibold text-amalfi-coast focus:outline-amalfi-coast",
        className,
      )}
      {...rest}
    />
  );
}

export default Link;
