import { useDocumentTitle } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useAuth } from "@/components/Auth";
import ErrorText from "@/components/ErrorText";
import Loader from "@/components/Loader";
import { useNotification } from "@/components/Notification";
import { PageTitle } from "@/constants/pages";
import { useStaffClientOneTimeCodeMutation } from "@/hooks/user";

type UiState =
  | {
      type: "loading";
    }
  | {
      type: "error";
    };

export default function StaffClientOneTimeCodePage() {
  const [searchParams] = useSearchParams();
  const showNotification = useNotification();
  const { signInWithToken } = useAuth();
  const [state, setState] = useState<UiState>({ type: "loading" });

  const exchangeStaffClientOneTimeCodeMutation =
    useStaffClientOneTimeCodeMutation();

  const handleSubmit = (code: string, controller: AbortController) => {
    exchangeStaffClientOneTimeCodeMutation.mutate(
      { code, signal: controller.signal },
      {
        onSuccess: ({ jwt, redirect_url, missingRequiredFields }) => {
          if (redirect_url) {
            localStorage.setItem("redirect", redirect_url);
          }
          showNotification(`You are now signed in`);
          signInWithToken({
            token: jwt,
            missingRequiredFields: missingRequiredFields,
          });
        },
        onError: () => {
          setState({ type: "error" });
        },
      },
    );
  };

  useEffect(() => {
    const abortController = new AbortController();
    const code = searchParams.get("code");

    if (code) {
      handleSubmit(code, abortController);
    } else {
      setState({ type: "error" });
    }

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useDocumentTitle(PageTitle.STAFF_CLIENT_ONE_TIME_CODE);

  if (state.type === "error") {
    return (
      <div>
        <ErrorText className={"text-center"}>
          There was a problem, please talk to the staff.
        </ErrorText>
      </div>
    );
  }

  return (
    <div>
      <Loader />
    </div>
  );
}
