import Button from "@/components/Button";
import Header from "@/components/Header";

type Props = {
  onStart: () => void;
  headerDescription: string;
  subHeaderDescription: string;
};

export default function WellnessPlanIntroPage({
  onStart,
  headerDescription,
  subHeaderDescription,
}: Props) {
  return (
    <>
      <Header className="mb-5 self-stretch border-b border-black/40 pb-5 text-4xl">
        <span className="text-amalfi-coast">Welcome.</span> Let’s get you
        started.
      </Header>

      <p className="mb-8 font-semibold">{headerDescription}</p>

      <p className="mb-12">{subHeaderDescription}</p>

      <Button onClick={() => onStart()} className="mb-12">
        Create My Wellness Plan
      </Button>

      <p className="mt-auto text-xs italic">
        This questionnaire is intended for persons 18 and older. Parents and
        guardians, please consult a Hyper Wellness Rep in-store to determine a
        protocol for persons under the age of 18.
      </p>
    </>
  );
}
