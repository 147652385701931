import { addDays, format, isBefore, isPast, parse } from "date-fns";
import { useMemo } from "react";
import { twMerge } from "tailwind-merge";

import type { PaymentMethod } from "@/services/payment";

import Badge from "./Badge";
import CardLogo from "./CardLogo";

type State = "active" | "expired" | "expiring" | "failed";

export default function PaymentMethodItem({
  paymentMethod,
  isExpanded,
  onToggleExpanded,
  onSetAsDefault,
  onRemove,
}: {
  paymentMethod: PaymentMethod;
  isExpanded: boolean;
  onToggleExpanded: () => void;
  onSetAsDefault: () => void;
  onRemove: () => void;
}) {
  const { id, issuer, last4, is_primary, expires } = paymentMethod;
  const year = expires.substring(2, 4);
  const month = expires.substring(5, 7);

  const state = useMemo<State>(() => {
    if (paymentMethod.status === "FAILED_LAST_CHARGE") {
      return "failed";
    }

    if (isPast(parse(expires.substring(0, 10), "yyyy-MM-dd", new Date()))) {
      return "expired";
    }

    if (
      isBefore(
        parse(expires.substring(0, 10), "yyyy-MM-dd", new Date()),
        addDays(new Date(), 30),
      )
    ) {
      return "expiring";
    }

    return "active";
  }, [paymentMethod, expires]);

  return (
    <li
      className={twMerge(
        "border-gray-300 border rounded-lg flex flex-col",
        is_primary && "bg-amalfi-coast/5 border-amalfi-coast",
      )}
      key={id}
    >
      <button
        type="button"
        className="flex items-center gap-3 p-4 focus:outline-amalfi-coast "
        onClick={onToggleExpanded}
      >
        <CardLogo issuer={issuer} />

        <p className="ml-2 mr-auto text-sm text-cold-and-dark/60">
          {issuer} **** {last4}
        </p>

        {is_primary && <Badge>Default</Badge>}

        {state === "expired" && <Badge variant="expired">Expired</Badge>}

        {state === "expiring" && (
          <Badge variant="expires">
            Expires {month}/{year}
          </Badge>
        )}

        {state === "active" && (
          <p className="text-xs">
            Expires {month}/{year}
          </p>
        )}

        {state === "failed" && (
          <Badge variant="failed">
            Failed{" "}
            {paymentMethod.last_charge_at &&
              format(new Date(paymentMethod.last_charge_at), "MM/yy")}
          </Badge>
        )}
      </button>

      <div className={twMerge("flex flex-col px-4", !isExpanded && "hidden")}>
        {!is_primary && (
          <button
            className="border-t border-gray-300 p-4 text-left font-semibold text-amalfi-coast focus:outline-amalfi-coast "
            onClick={onSetAsDefault}
            type="button"
          >
            Set card as default
          </button>
        )}
        <button
          className="border-t border-gray-300 p-4 text-left font-semibold text-sunset-orange focus:outline-amalfi-coast  disabled:pb-2 disabled:text-cold-and-dark/40"
          onClick={onRemove}
          type="button"
          disabled={is_primary}
        >
          Remove card
        </button>
        {is_primary && (
          <p className="px-4 pb-4 text-sm  text-cold-and-dark/40">
            In order to remove this card, please add an alternative card
          </p>
        )}
      </div>
    </li>
  );
}
