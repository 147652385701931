import { useEffect } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";

import { useAuth } from "@/components/Auth";
import Link from "@/components/Link";
import { PageRoute } from "@/constants/pages";
import { useCampaignTracking } from "@/hooks/campaign";
import { useIsDesktop } from "@/hooks/media-queries";
import { usePersistedStore } from "@/hooks/stores";

import { DesktopMainMenu, MobileMainMenu, UserMenu } from "./Menu";

function Header() {
  const { user } = useAuth();
  const isDesktop = useIsDesktop();

  return (
    <header className="border-b border-solid border-gray-300">
      {isDesktop ? (
        <div className="flex items-center justify-start">
          <Link to={PageRoute.HOME} as={NavLink} className="px-8 py-4">
            <img
              src="/logo.svg"
              alt="Restore - Hyper Wellness"
              width="132"
              height="42"
            />
          </Link>
          {user && (
            <>
              <DesktopMainMenu />

              <UserMenu />
            </>
          )}
        </div>
      ) : (
        <div className="grid grid-cols-[56px_1fr_56px] items-center">
          {user && <MobileMainMenu />}

          <Link
            to={PageRoute.HOME}
            as={NavLink}
            className="flex justify-center px-8 py-4"
          >
            <img
              src="/logo.svg"
              alt="Restore - Hyper Wellness"
              width="132"
              height="42"
            />
          </Link>

          {user && <UserMenu />}
        </div>
      )}
    </header>
  );
}

export function StandardContent({ children }: { children: React.ReactNode }) {
  return (
    <main className="mx-auto w-full max-w-lg flex-1 px-4 py-10 sm:p-10">
      {children}
    </main>
  );
}

export default function App() {
  const { pathname } = useLocation();
  usePersistedStore();
  useCampaignTracking();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);

  return (
    <div className="flex min-h-screen flex-col">
      <Header />

      <Outlet />

      <footer className="grid gap-8 bg-ruskin-blue p-10 text-white md:grid-cols-3 md:items-center md:p-8">
        <div className="flex flex-col gap-1 text-center md:text-left">
          <div className="font-semibold">Download the Restore App</div>
          <p>
            <a
              href={import.meta.env.VITE_APPLE_APP_STORE_URL}
              target="_blank"
              rel="noreferrer"
              className="hover:underline focus:outline-white"
            >
              Visit the App Store
            </a>
          </p>

          <p>
            <a
              href={import.meta.env.VITE_GOOGLE_PLAY_STORE_URL}
              target="_blank"
              rel="noreferrer"
              className="hover:underline focus:outline-white"
            >
              Visit Google Play
            </a>
          </p>
        </div>
        <div className="text-center">
          <p className="mb-2 text-xs italic">
            &copy;{new Date().getFullYear()} Restore Hyper Wellness all rights
            reserved.
          </p>

          <p className="text-xs">
            {APP_VERSION} - {BRANCH_NAME}
          </p>
        </div>
      </footer>
    </div>
  );
}
