import React from "react";
import { twMerge } from "tailwind-merge";

type Props = React.HTMLProps<HTMLLabelElement> & {
  className?: string;
};

const Label = React.forwardRef<HTMLLabelElement, Props>(
  ({ className, ...rest }: Props, ref) => {
    return (
      <label
        className={twMerge("font-semibold text-sm", className)}
        {...rest}
        ref={ref}
      />
    );
  },
);

export default Label;
