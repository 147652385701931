import { twMerge } from "tailwind-merge";

import CheckIcon from "./check.svg?react";
import NotCheckedIcon from "./not-checked.svg?react";

const requirements = [
  {
    regex: /[A-Z]/,
    text: "Upper-case letter",
  },
  {
    regex: /[a-z]/,
    text: "Lower-case letter",
  },
  {
    regex: /[0-9]/,
    text: "Number",
  },
  {
    regex: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
    text: "Special character (e.g. !@#$)",
  },
  {
    regex: /.{8,}/,
    text: "8 characters or more",
  },
];

export default function PasswordRequirements({
  password,
}: {
  password?: string;
}) {
  if (!password) {
    return null;
  }
  return (
    <ul className="my-4 space-y-1" aria-live="polite">
      {requirements.map((requirement) => (
        <li className="flex items-center gap-2" key={requirement.text}>
          <div
            className={twMerge(
              "w-4 h-4",
              requirement.regex.test(password)
                ? "text-capitalino-cactus"
                : "text-gray-300",
            )}
          >
            {requirement.regex.test(password) ? (
              <CheckIcon aria-hidden />
            ) : (
              <NotCheckedIcon aria-hidden />
            )}
          </div>
          <div
            className={
              requirement.regex.test(password)
                ? "text-gray-500"
                : "text-gray-400"
            }
            data-requirement-met={
              requirement.regex.test(password) ? "true" : "false"
            }
          >
            {requirement.text}
          </div>
        </li>
      ))}
    </ul>
  );
}
