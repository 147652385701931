import "./styles/index.css";

import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import App from "@/components/App";
import { AuthProvider, RequireAuth } from "@/components/Auth";
import { NotificationProvider } from "@/components/Notification";
import AccountSecurityPage from "@/pages/AccountSecurity";
import AppointmentPage from "@/pages/Appointments";
import AuthPage from "@/pages/Auth";
import BookingPage from "@/pages/Booking";
import HomePage from "@/pages/Home";
import PaymentMethodsPage from "@/pages/PaymentMethods";
import ProfilePage from "@/pages/Profile";

import { FeaturesProvider } from "./components/Features";
import DeleteAccountPage from "./pages/DeleteAccount";
import WellnessPlanPage from "./pages/WellnessPlan";

if (import.meta.env.MODE === "test") {
  const { worker } = await import("./mocks/browser");
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  worker.start();
  worker.listHandlers().forEach((handler) => {
    // eslint-disable-next-line no-console
    console.log(handler.info.header);
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    release: `consumer-web-react@${APP_VERSION}`,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    tracesSampleRate: 0.2,
    environment: import.meta.env.VITE_SENTRY_ENV || "local",
  });
}

if (import.meta.env.VITE_GOOGLE_TAG_MANAGER) {
  TagManager.initialize({
    gtmId: import.meta.env.VITE_GOOGLE_TAG_MANAGER,
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <NotificationProvider>
        <BrowserRouter>
          <AuthProvider>
            <FeaturesProvider>
              <Routes>
                <Route element={<App />}>
                  <Route path="auth/*" element={<AuthPage />} />
                  <Route path="/" element={<RequireAuth />}>
                    <Route
                      path="payment-methods/*"
                      element={<PaymentMethodsPage />}
                    />
                    <Route
                      path="delete-account/*"
                      element={<DeleteAccountPage />}
                    />
                    <Route path="profile/*" element={<ProfilePage />} />
                    <Route
                      path="account-security"
                      element={<AccountSecurityPage />}
                    />
                    <Route
                      path="plans/:slug/*"
                      element={<WellnessPlanPage />}
                    />
                    <Route
                      path="appointments/*"
                      element={<AppointmentPage />}
                    />
                    <Route path="book/*" element={<BookingPage />} />
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route index element={<HomePage />} />
                  </Route>
                </Route>
              </Routes>
            </FeaturesProvider>
          </AuthProvider>
        </BrowserRouter>
      </NotificationProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
