import { useQuery } from "@tanstack/react-query";

import { fetchAppointment } from "@/services/booking";

export const useAppointmentQuery = (id?: string) =>
  useQuery({
    queryKey: ["appointments", id],
    queryFn: () => fetchAppointment(id),
    enabled: Boolean(id),
  });
