import { useDocumentTitle } from "@uidotdev/usehooks";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";

import AppointmentCard from "@/components/AppointmentCard";
import { useAuth } from "@/components/Auth";
import Button from "@/components/Button";
import DownloadAppCTA from "@/components/DownloadAppCTA";
import Header from "@/components/Header";
import Link from "@/components/Link";
import Loader from "@/components/Loader";
import StoreCard from "@/components/StoreCard";
import WaiverCTA from "@/components/WaiverCTA";
import { ExternalLinks } from "@/constants/links";
import { PageRoute, PageTitle } from "@/constants/pages";
import { useAppointmentQuery } from "@/hooks/appointments";
import { usePageEventTracking } from "@/hooks/event-tracking";
import { useStoreQuery } from "@/hooks/stores";

export default function AppointmentDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { waiver } = useAuth();
  const appointmentQuery = useAppointmentQuery(id);
  const storeQuery = useStoreQuery(appointmentQuery.data?.store_code);

  usePageEventTracking({
    service_name: appointmentQuery.data?.service_description,
    service_date: appointmentQuery.data?.start_datetime,
  });

  useDocumentTitle(
    PageTitle.APPOINTMENT(appointmentQuery.data?.service_description ?? ""),
  );

  function handleWaiverCTAClick() {
    navigate(PageRoute.ACCOUNT_PROFILE);
  }

  if (!id) {
    return <Navigate to={PageRoute.HOME} />;
  }

  if (
    !(appointmentQuery.isSuccess && storeQuery.isSuccess) ||
    !storeQuery.data
  ) {
    return <Loader />;
  }

  return (
    <>
      <Header className="mb-6">Your upcoming appointment</Header>

      <AppointmentCard
        className="mb-8"
        date={appointmentQuery.data.start_datetime}
        serviceName={appointmentQuery.data.service_description}
        storeName={storeQuery.data.description}
      />

      {storeQuery.data && (
        <StoreCard className="mb-8" store={storeQuery.data} />
      )}

      <p className="mb-4">
        Please arrive at least 10 minutes before your scheduled appointment to
        ensure that you have enough time to prepare for your service.
      </p>

      <p className="mb-6">
        Check out our{" "}
        <Link href={ExternalLinks.FAQS} target="_blank">
          FAQs for more frequently asked questions and answers
        </Link>{" "}
        around what to expect from your visit.
      </p>

      <div className="mb-4 flex flex-col gap-4">
        {waiver.state === "VALID" && (
          <Button
            as={NavLink}
            to={{
              pathname: PageRoute.APPOINTMENT_BOOK,
              search: `service=${appointmentQuery.data.service_id}`,
            }}
          >
            Book this service again
          </Button>
        )}

        {waiver.state === "NOT_SIGNED" && (
          <WaiverCTA onClick={handleWaiverCTAClick} />
        )}

        {waiver.state === "EXPIRED" && (
          <WaiverCTA
            expiresAt={waiver.expiresAt}
            onClick={handleWaiverCTAClick}
          />
        )}

        <Button
          as={NavLink}
          to={PageRoute.APPOINTMENT_CANCEL(id ?? "")}
          variant="secondary"
          color="red"
        >
          Cancel appointment
        </Button>
      </div>

      <DownloadAppCTA />
    </>
  );
}
