import { useDocumentTitle } from "@uidotdev/usehooks";
import { format } from "date-fns";
import { Navigate, NavLink, useLocation } from "react-router-dom";

import AppointmentCard from "@/components/AppointmentCard";
import { useAuth } from "@/components/Auth";
import Button from "@/components/Button";
import DownloadAppCTA from "@/components/DownloadAppCTA";
import Header from "@/components/Header";
import Link from "@/components/Link";
import Loader from "@/components/Loader";
import StoreCard from "@/components/StoreCard";
import { ExternalLinks } from "@/constants/links";
import { PageRoute, PageTitle } from "@/constants/pages";
import { usePageEventTracking } from "@/hooks/event-tracking";
import { useStoreQuery } from "@/hooks/stores";
import { BookAppointmentResponse } from "@/services/booking";

export default function BookingSuccessPage() {
  const location = useLocation();
  const appointment = location.state as BookAppointmentResponse;
  const { waiver } = useAuth();
  const storeQuery = useStoreQuery();

  usePageEventTracking({
    service_name: appointment?.service_name,
    service_date: appointment?.start,
  });

  useDocumentTitle(PageTitle.APPOINTMENT_SUCCESS);

  if (!appointment) {
    return <Navigate to={PageRoute.APPOINTMENT_BOOK} />;
  }

  if (!storeQuery.isSuccess) {
    return <Loader />;
  }

  return (
    <>
      <Header className="mb-6">Booking confirmed!</Header>

      <AppointmentCard
        className="mb-8"
        date={appointment.start}
        serviceName={appointment.service_name}
        storeName={appointment.store.name}
      />

      {storeQuery.data && (
        <StoreCard className="mb-8" store={storeQuery.data} />
      )}

      <p className="mb-4">
        Please arrive at least 10 minutes before your scheduled appointment to
        ensure that you have enough time to prepare for your service.
      </p>

      <p className="mb-8">
        Check out our{" "}
        <Link href={ExternalLinks.FAQS} target="_blank">
          FAQs for more frequently asked questions and answers
        </Link>{" "}
        around what to expect from your visit.
      </p>

      <div className="mb-4 flex flex-col gap-4">
        {waiver.state === "VALID" ? (
          <Button className="" as={NavLink} to={PageRoute.APPOINTMENT_BOOK}>
            Book another appointment
          </Button>
        ) : (
          <div className="flex flex-col gap-1 bg-linx-white p-4">
            <p className="font-semibold">Action is required</p>

            {waiver.state === "NOT_SIGNED" && (
              <>
                <p>
                  We require you to complete a mandatory waiver before you can
                  receive any of our services.
                </p>
                <p>
                  Please take the time to complete a waiver prior to attending
                  your appointment.
                </p>
              </>
            )}

            {waiver.state === "EXPIRED" && (
              <>
                <p>
                  We require you to complete a mandatory waiver before you can
                  receive any of our services.
                </p>
                {waiver.expiresAt && (
                  <p>
                    Your waiver will expire{" "}
                    <strong>{format(waiver.expiresAt, "MMMM do, yyyy")}</strong>
                    .
                  </p>
                )}
                <p>
                  Please take the time to complete a new waiver prior to
                  attending your appointment.
                </p>
              </>
            )}

            <Button
              className="mt-2"
              as={NavLink}
              to={PageRoute.ACCOUNT_PROFILE}
            >
              Complete waiver
            </Button>
          </div>
        )}

        <Button as={NavLink} to={PageRoute.HOME} variant="secondary">
          Done
        </Button>
      </div>

      <DownloadAppCTA />
    </>
  );
}
