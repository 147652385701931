import { useDocumentTitle } from "@uidotdev/usehooks";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Loader from "@/components/Loader";
import { useNotification } from "@/components/Notification";
import { PageRoute, PageTitle } from "@/constants/pages";
import { useGetPlan } from "@/hooks/wellness-plan";

import WellnessPlanPlanPage from "./Plan";

export default function WellnessPlanResultsPage() {
  const { slug = "", id = "" } = useParams();
  const notify = useNotification();
  const navigate = useNavigate();
  const getPlanQuery = useGetPlan({ slug, id });

  useDocumentTitle(PageTitle.WELLNESS_PLAN);

  useEffect(() => {
    if (getPlanQuery.isError) {
      notify("Sorry, we could not find that plan", "error");
      navigate(PageRoute.WELLNESS_PLAN_HISTORY);
    }
  }, [navigate, notify, getPlanQuery.isError]);

  if (getPlanQuery.isPending || !getPlanQuery.data) {
    return (
      <div className="flex flex-1 flex-col p-5 lg:px-16 lg:py-10">
        <Loader />
      </div>
    );
  }

  return (
    <main className="flex flex-1 bg-child-of-light">
      <WellnessPlanPlanPage
        plan={getPlanQuery.data.plan}
        isConfirmed={!!getPlanQuery.data.plan_confirmed_datetime}
        promos={getPlanQuery.data.promos}
      />
    </main>
  );
}
