import type { PaymentMethodIssuer } from "@/services/payment";

import amexLogo from "./logos/amex.webp";
import dinersLogo from "./logos/diners.webp";
import discoverLogo from "./logos/discover.webp";
import jcbLogo from "./logos/jcb.webp";
import mastercardLogo from "./logos/mastercard.webp";
import visaLogo from "./logos/visa.webp";

export default function CardLogo({ issuer }: { issuer: PaymentMethodIssuer }) {
  switch (issuer) {
    case "VISA":
      return <img src={visaLogo} height={22} width={35} />;
    case "MASTERCARD":
      return <img src={mastercardLogo} height={22} width={35} />;
    case "AMEX":
      return <img src={amexLogo} height={22} width={35} />;
    case "DISCOVER":
      return <img src={discoverLogo} height={22} width={35} />;
    case "DINERS":
      return <img src={dinersLogo} height={22} width={35} />;
    case "JCB":
      return <img src={jcbLogo} height={22} width={35} />;
    default:
      return null;
  }
}
