import { twMerge } from "tailwind-merge";

export default function Badge({
  children,
  variant,
}: {
  children: React.ReactNode;
  variant?: "expires" | "expired" | "failed";
}) {
  return (
    <div
      className={twMerge(
        "text-xs rounded-md p-1 text-black bg-sunset-orange/60",
        variant === "expired" && "bg-sunset-orange/60",
        variant === "expires" && "bg-sunset-orange",
        variant === "failed" && "bg-cold-and-dark/40 text-white",
        !variant && "bg-cold-and-dark/20",
      )}
    >
      {children}
    </div>
  );
}
