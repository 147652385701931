import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { StandardContent } from "@/components/App";
import { useAuth } from "@/components/Auth";
import { PageRoute } from "@/constants/pages";
import { usePageEventTracking } from "@/hooks/event-tracking";
import StaffClientOneTimeCodePage from "@/pages/Auth/StaffClientOneTimeCode";
import RequiredInfoPage from "@/pages/RequiredInfo";

import EmailPage from "./Email";
import PasswordResetPage from "./PasswordReset";
import SetNewPasswordPage from "./SetPassword";
import SignInPage from "./SignIn";
import SignInWithCodePage from "./SignInWithCode";
import SignUpPage from "./SignUp";

const REDIRECT_LOGIC_PATHS = [
  PageRoute.REQUIRED_INFO,
  PageRoute.STAFF_CLIENT_ONE_TIME_CODE,
] as string[];

export default function AuthPage() {
  const { isSignedIn } = useAuth();
  const path = useMemo(() => window.location.pathname, []);

  usePageEventTracking();

  // one time code path has redirect logic
  if (isSignedIn && !REDIRECT_LOGIC_PATHS.includes(path)) {
    return <Navigate to={PageRoute.HOME} />;
  }

  return (
    <StandardContent>
      <Routes>
        <Route path="one-time-code" element={<StaffClientOneTimeCodePage />} />
        <Route path="password-reset/set" element={<SetNewPasswordPage />} />
        <Route path="password-reset" element={<PasswordResetPage />} />
        <Route path="sign-in/code" element={<SignInWithCodePage />} />
        <Route path="sign-in" element={<SignInPage />} />
        <Route path="sign-up" element={<SignUpPage />} />
        <Route path="required-info" element={<RequiredInfoPage />} />
        <Route path="*" element={<Navigate to={PageRoute.HOME} />} />
        <Route index element={<EmailPage />} />
      </Routes>
    </StandardContent>
  );
}
