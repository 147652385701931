import { fetchJSONFactory } from "@/utils/fetch-json";

const fetchJSON = fetchJSONFactory(import.meta.env.VITE_FORMS_API);

type Answer = {
  slug: string;
  text: string;
  order: number;
};

export type Question = {
  slug: string;
  text: string;
  answers: Answer[];
};

export type PreviousAnswer = {
  question: string;
  answer: string;
};

export type StartQuestionnaireResponse = {
  question: Question;
  session: string;
  previousAnswers: PreviousAnswer[];
  questionIndex: number;
  totalQuestions: number;
};

type StartQuestionnaireParams = {
  reset: boolean;
  slug: string;
};

export function startQuestionnaire({ slug, reset }: StartQuestionnaireParams) {
  return fetchJSON<StartQuestionnaireResponse>("questionnaire/start", {
    body: {
      questionnaireSlug: slug,
      resetSession: reset,
    },
    method: "POST",
  });
}

export const Direction = {
  PREVIOUS: "PREVIOUS",
  NEXT: "NEXT",
} as const;

type GetQuestionParams = {
  session: string;
  direction: (typeof Direction)[keyof typeof Direction];
  question: string;
  answer?: string;
};

export type PlanService = {
  slug: string;
  didYouKnow: string;
  benefits: string[];
  whatToExpect: string[];
  frequency: {
    times: string;
  };
  icon: string;
  description: string;
};

type PlanServiceVisit = {
  num: number;
  services: string[];
};

type PlanServiceWeek = {
  num: number;
  visits: PlanServiceVisit[];
};

export type Plan = {
  session: string;
  slug: string;
  version: number;
  services: PlanService[];
  weeks: PlanServiceWeek[];
  customerPlanHeader: string;
};

export type PlanPromoCTA = {
  text: string;
  link: string;
};

export type PlanPromo = {
  title: string;
  subTitle: string | null;
  body: string;
  primaryCTA: PlanPromoCTA;
};

export const GetQuestionResponseType = {
  QUESTION: "question",
  PLAN: "plan",
} as const;

export type GetQuestionResponse =
  | {
      type: typeof GetQuestionResponseType.QUESTION;
      question: Question;
      questionIndex: number;
      totalQuestions: number;
    }
  | {
      type: typeof GetQuestionResponseType.PLAN;
      plan: Plan;
      promos: PlanPromo[];
    };

export function getQuestion({
  question,
  session,
  direction,
  answer,
}: GetQuestionParams) {
  return fetchJSON<GetQuestionResponse>("questionnaire/questions", {
    body: {
      question,
      session,
      direction,
      answer,
    },
    method: "POST",
  });
}

type CompleteQuestionnaireParams = {
  session: string;
};

export type CompleteQuestionnaireResponse = {
  success: boolean;
};

export function completeQuestionnaire({
  session,
}: CompleteQuestionnaireParams) {
  return fetchJSON<CompleteQuestionnaireResponse>("questionnaire/complete", {
    body: {
      session,
    },
    method: "POST",
  });
}

type GetQuestionnaireInfoParams = {
  slug: string;
};

export type GetQuestionnaireInfoResponse = {
  headerDescription: string;
  subHeaderDescription: string;
};

export function getQuestionnaireInfo({ slug }: GetQuestionnaireInfoParams) {
  return fetchJSON<GetQuestionnaireInfoResponse>("questionnaire/info", {
    params: { slug },
  });
}

type EmailQuestionnaireParams = {
  session: string;
  email: string;
};

type EmailQuestionnaireResponse = {
  data: {
    success: boolean;
    planConfirmed: boolean;
  };
};

export function emailQuestionnaire({
  session,
  email,
}: EmailQuestionnaireParams) {
  return fetchJSON<EmailQuestionnaireResponse>("questionnaire/email", {
    body: {
      session,
      email,
    },
    method: "POST",
  });
}

type GetPlansResponse = GetPlansResponseItem[];

type GetPlansResponseItem = {
  questionnaire_slug: string;
  plan_confirmed_datetime: string;
  plan: Plan;
  questions: {
    description: string;
    answer: string;
    order: number;
  }[];
  intro_verbiage: string;
  was_staff_created: boolean;
  timestamp: number;
  promos: PlanPromo[];
};

export function getPlans() {
  return fetchJSON<GetPlansResponse>("users/plans");
}

type GetPlanParams = {
  slug: string;
  id: string;
};

type GetPlanResponse = GetPlansResponseItem;

export function getPlan({ slug, id }: GetPlanParams) {
  return fetchJSON<GetPlanResponse>(`users/plans/${slug}/${id}`);
}
