import Header from "@/components/Header";
import { useIsDesktop } from "@/hooks/media-queries";

import AppleAppStoreBadge from "./apple-app-store.svg";
import AppleAppStoreQRCode from "./apple-app-store-qr.webp";
import GooglePlayStoreBadge from "./google-play-store.svg";
import GooglePlayStoreQRCode from "./google-play-store-qr.webp";

export default function DownloadAppCTA() {
  const isDesktop = useIsDesktop();
  const linkClassees =
    "bg-white border rounded border-cold-and-dark/20 py-4 px-6 flex flex-col gap-4 focus:outline-amalfi-coast";

  return (
    <div className="bg-linx-white p-4">
      <Header className="mb-3 text-xl" as="h2">
        Download the Restore App
      </Header>
      <p className="mb-5">
        Schedule and manage all of your appointments quickly and effortlessly -
        even on the go!
      </p>

      {isDesktop ? (
        <div className="flex flex-wrap justify-between gap-2">
          <a
            href={import.meta.env.VITE_APPLE_APP_STORE_URL}
            target="_blank"
            rel="noreferrer"
            className={linkClassees}
          >
            <img
              src={AppleAppStoreBadge}
              alt="Download on the App Store"
              width="144"
              height="42"
            />
            <img
              src={AppleAppStoreQRCode}
              alt="Apple App Store QR Code to download Restore App"
              width="145"
              height="145"
            />
          </a>
          <a
            href={import.meta.env.VITE_GOOGLE_PLAY_STORE_URL}
            target="_blank"
            rel="noreferrer"
            className={linkClassees}
          >
            <img
              src={GooglePlayStoreBadge}
              alt="Get it on Google Play"
              width="142"
              height="42"
            />
            <img
              src={GooglePlayStoreQRCode}
              alt="Google Play QR Code"
              width="145"
              height="145"
            />
          </a>
        </div>
      ) : (
        <div className="flex flex-wrap justify-center gap-2">
          <a
            href={import.meta.env.VITE_APPLE_APP_STORE_URL}
            target="_blank"
            rel="noreferrer"
            className="focus:outline-amalfi-coast"
          >
            <img
              src={AppleAppStoreBadge}
              alt="Download on the App Store"
              width="177"
              height="57"
            />
          </a>
          <a
            href={import.meta.env.VITE_GOOGLE_PLAY_STORE_URL}
            target="_blank"
            rel="noreferrer"
            className="focus:outline-amalfi-coast"
          >
            <img
              src={GooglePlayStoreBadge}
              alt="Get it on Google Play"
              width="190"
              height="57"
            />
          </a>
        </div>
      )}
    </div>
  );
}
