import { useMutation, useQuery } from "@tanstack/react-query";

import { useAuth } from "@/components/Auth";
import {
  bookAppointment,
  fetchAvailableTimes,
  fetchDashboard,
  fetchFeatures,
  fetchServiceRecommendations,
  fetchServices,
} from "@/services/booking";

export const useDashboardQuery = () =>
  useQuery({
    queryKey: ["dashboard"],
    queryFn: fetchDashboard,
  });

export const useFeaturesQuery = () => {
  const { isSignedIn } = useAuth();
  return useQuery({
    queryKey: ["features"],
    queryFn: fetchFeatures,
    enabled: isSignedIn,
  });
};

export const useServiceRecommendationsQuery = () =>
  useQuery({
    queryKey: ["service-recommendations"],
    queryFn: fetchServiceRecommendations,
  });

type UseAvailableTimesQueryParams = {
  storeCode: string;
  serviceId?: string;
  date?: string;
};

export const useAvailableTimesQuery = ({
  storeCode,
  serviceId,
  date,
}: UseAvailableTimesQueryParams) =>
  useQuery({
    queryKey: ["available-times", storeCode, serviceId, date],
    enabled: Boolean(serviceId && date),
    queryFn: () => {
      if (serviceId && date) {
        return fetchAvailableTimes(storeCode, serviceId, date);
      }
    },
  });

export const useServicesQuery = (storeCode: string) =>
  useQuery({
    queryKey: ["services", storeCode],
    queryFn: () => fetchServices(storeCode),
  });

export const useBookAppointmentMutation = () =>
  useMutation({ mutationFn: bookAppointment });
