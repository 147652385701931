import { useDocumentTitle } from "@uidotdev/usehooks";
import { format, isToday } from "date-fns";
import { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { StandardContent } from "@/components/App";
import { useAuth } from "@/components/Auth";
import Button from "@/components/Button";
import DownloadAppCTA from "@/components/DownloadAppCTA";
import { useFeatures } from "@/components/Features";
import Header from "@/components/Header";
import Icon from "@/components/Icon";
import Link from "@/components/Link";
import Loader from "@/components/Loader";
import { PageRoute, PageTitle } from "@/constants/pages";
import {
  useDashboardQuery,
  useServiceRecommendationsQuery,
} from "@/hooks/booking";
import type { Appointment } from "@/services/booking";

import headerBackground from "./header-bkgd.svg";
import ServiceCompressionBackground from "./service_compression_white.png";
import ServiceCryoBackground from "./service_cryo_white.png";
import ServiceHydrafacialBackground from "./service_hydrafacial_white.png";
import ServiceIVDripBackground from "./service_IVDrip_white.png";
import ServiceRedlightBackground from "./service_redlight_white.png";
import ServiceSaunaBackground from "./service_sauna_white.png";

const MAX_APPOINTMENTS_TO_SHOW = 3;

const SERVICE_BUTTONS: Record<number, { color: string; image: string }> = {
  121001001: {
    color: "#027797CC",
    image: ServiceCryoBackground,
  },
  123001001: {
    color: "#EF554FCC",
    image: ServiceRedlightBackground,
  },
  122001001: {
    color: "#F38058CC",
    image: ServiceSaunaBackground,
  },
  142008001: {
    color: "#268579",
    image: ServiceHydrafacialBackground,
  },
  124002001: {
    color: "#F6A4A7",
    image: ServiceCompressionBackground,
  },
  211000000: {
    color: "#49B6D4",
    image: ServiceIVDripBackground,
  },
};

function AppointmentItem({
  appointment,
  className,
}: {
  appointment: Appointment;
  className: string;
}) {
  const startDateTime = new Date(appointment.start_datetime);
  const isAppointmentToday = isToday(startDateTime);
  return (
    <li key={appointment.id}>
      <NavLink
        to={`appointments/${appointment.id}`}
        className={twMerge(
          "rounded-md py-3 px-5 bg-amalfi-coast/5 flex flex-col",
          isAppointmentToday && "bg-amalfi-coast/20",
          className,
        )}
      >
        <div className="mb-2 flex items-center gap-2 font-semibold">
          <Icon name="calendar" />
          {isAppointmentToday ? "TODAY" : format(startDateTime, "P")}
          <span className="font-normal"> at {format(startDateTime, "p")}</span>
        </div>
        <div className="font-semibold">{appointment.service_description}</div>
        <div className="text-sm">{appointment.store_description}</div>
      </NavLink>
    </li>
  );
}

function Home() {
  const { isWellnessPlanEnabled } = useFeatures();
  const [showAllUpcomingAppointments, setShowAllUpcomingAppointments] =
    useState(false);
  const { user } = useAuth();
  const dashboardQuery = useDashboardQuery();
  const serviceRecommendationsQuery = useServiceRecommendationsQuery();
  const upcomingAppointments = useMemo(() => {
    if (!dashboardQuery.data?.upcoming) {
      return [];
    }

    return dashboardQuery.data.upcoming.sort((a, b) => {
      return (
        new Date(a.start_datetime).getTime() -
        new Date(b.start_datetime).getTime()
      );
    });
  }, [dashboardQuery.data?.upcoming]);

  const needsToCompleteWaiver = useMemo(() => {
    if (!dashboardQuery.data?.actions) {
      return false;
    }

    return dashboardQuery.data?.actions.needs_waiver;
  }, [dashboardQuery.data?.actions]);

  const needsToCompleteWellnessPlan = useMemo(() => {
    if (!dashboardQuery.data?.actions || !isWellnessPlanEnabled) {
      return false;
    }

    return !dashboardQuery.data?.actions.has_completed_wellness_plan;
  }, [dashboardQuery.data?.actions, isWellnessPlanEnabled]);

  useDocumentTitle(PageTitle.HOME);

  if (!dashboardQuery.isSuccess || !serviceRecommendationsQuery.isSuccess) {
    return <Loader />;
  }

  return (
    <>
      <div
        className="mb-6 rounded-lg bg-studer-blue px-5 py-8"
        style={{
          backgroundImage: `url(${headerBackground}), url(${headerBackground}), url(${headerBackground})`,
          backgroundRepeat: `no-repeat, no-repeat, no-repeat`,
          backgroundSize: `138px, 38px, 95px`,
          backgroundPosition: `top -40px right -40px, bottom 10px right 100px, top -50px left -20px`,
        }}
      >
        <Header className="text-white md:text-center">
          Welcome back {user?.first_name}!
        </Header>
      </div>

      {(needsToCompleteWellnessPlan || needsToCompleteWaiver) && (
        <>
          <Header className="mb-4 text-xl" as="h2">
            Get Ready for your Appointment
          </Header>

          <ul className="mb-6 border-t border-gray-300">
            {needsToCompleteWaiver && (
              <li>
                <NavLink
                  to={PageRoute.ACCOUNT_PROFILE}
                  className="flex flex-col gap-1 border-b border-gray-300 py-2"
                >
                  <div className="font-semibold text-amalfi-coast">
                    Core Services Waiver
                  </div>
                  <div className="text-sm text-sunset-orange">
                    Need to complete
                  </div>
                </NavLink>
              </li>
            )}

            {needsToCompleteWellnessPlan && (
              <li>
                <NavLink
                  to={PageRoute.WELLNESS_PLAN}
                  className="flex flex-col gap-1 border-b border-gray-300 py-2"
                >
                  <div className="font-semibold text-amalfi-coast">
                    Wellness Plan
                  </div>
                  <div className="text-sm text-capitalino-cactus">
                    Begin your journey
                  </div>
                </NavLink>
              </li>
            )}
          </ul>
        </>
      )}

      <div className="mb-4 flex items-center justify-between">
        <Header className="text-xl" as="h2">
          Upcoming Appointments
        </Header>
        {upcomingAppointments.length > MAX_APPOINTMENTS_TO_SHOW && (
          <Link
            onClick={() => setShowAllUpcomingAppointments((s) => !s)}
            className="text-right text-xs"
          >
            {showAllUpcomingAppointments ? "See less" : "See all"}
          </Link>
        )}
      </div>

      {upcomingAppointments.length ? (
        <ul className="mb-6 grid grid-cols-1 gap-2">
          {upcomingAppointments.map((appointment, index) => (
            <AppointmentItem
              key={appointment.id}
              appointment={appointment}
              className={twMerge(
                index >= MAX_APPOINTMENTS_TO_SHOW &&
                  !showAllUpcomingAppointments &&
                  "hidden",
              )}
            />
          ))}
        </ul>
      ) : (
        <p className="mb-6">You have no upcoming appointments</p>
      )}

      <Button
        as={NavLink}
        to={PageRoute.APPOINTMENT_BOOK}
        className="mb-7 block w-full text-center"
      >
        Book a new appointment
      </Button>

      <Header className="mb-4 text-xl" as="h2">
        Try Something New...
      </Header>

      <ul className="mb-6 grid grid-cols-3 gap-2">
        {serviceRecommendationsQuery.data.map((service) => (
          <li key={service.service_id}>
            <NavLink
              to={{
                pathname: PageRoute.APPOINTMENT_BOOK,
                search: `service=${service.service_id}`,
              }}
              className="flex aspect-square items-center justify-center rounded-md py-3 text-center font-poppins text-sm font-semibold text-white focus:outline-amalfi-coast"
              style={{
                backgroundColor: SERVICE_BUTTONS[service.service_id].color,
                backgroundSize: "cover",
                backgroundImage: `url(${
                  SERVICE_BUTTONS[service.service_id].image
                })`,
              }}
            >
              {service.name}
            </NavLink>
          </li>
        ))}
      </ul>
      <DownloadAppCTA />
    </>
  );
}

export default function HomePage() {
  return (
    <StandardContent>
      <Home />
    </StandardContent>
  );
}
