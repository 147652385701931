import { format } from "date-fns";

import Button from "@/components/Button";

type Props = {
  expiresAt?: Date;
  onClick: () => void;
};

export default function WaiverCTA({ expiresAt, onClick }: Props) {
  return (
    <div className="flex flex-col gap-1 bg-linx-white p-4">
      <p className="font-semibold">Action is required</p>

      {expiresAt ? (
        <>
          <p>
            We require you to complete a mandatory waiver before you can receive
            any of our services.
          </p>
          <p>
            Your waiver will expire{" "}
            <strong>{format(expiresAt, "MMMM do, yyyy")}</strong>.
          </p>
          <p>
            Please take the time to complete a new waiver prior to attending
            your appointment.
          </p>
        </>
      ) : (
        <>
          <p>
            We require you to complete a mandatory waiver before you can receive
            any of our services.
          </p>
          <p>
            Please take the time to complete a waiver prior to attending your
            appointment.
          </p>
        </>
      )}

      <Button type="button" onClick={onClick} className="mt-2">
        Complete waiver
      </Button>
    </div>
  );
}
