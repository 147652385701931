import { useQueryClient } from "@tanstack/react-query";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useState } from "react";

import { StandardContent } from "@/components/App";
import Header from "@/components/Header";
import Link from "@/components/Link";
import Loader from "@/components/Loader";
import { useNotification } from "@/components/Notification";
import { AddCardForm } from "@/components/Stripe";
import { ExternalLinks } from "@/constants/links";
import { PageTitle } from "@/constants/pages";
import { usePageEventTracking } from "@/hooks/event-tracking";
import {
  PAYMENT_MENTHODS_QUERY_KEY,
  usePaymentMethodsQuery,
  useRemovePaymentMethodMutation,
  useSetPaymentMethodAsPrimaryMutation,
} from "@/hooks/payment";
import { useSetUserHasCardOnFile } from "@/hooks/user";

import PaymentMethodItem from "./PaymentMethodItem";
import PlusIcon from "./plus.svg?react";
import UpIcon from "./up.svg?react";

function PaymentMethods() {
  const [isShowingAddCardForm, setIsShowingAddCardForm] = useState(false);
  const queryClient = useQueryClient();
  const setUserHasCardOnFile = useSetUserHasCardOnFile();
  const notify = useNotification();
  const [expandedPaymentMethodId, setExpandedPaymentMethodId] = useState<
    number | null
  >(null);
  const paymentMethodsQuery = usePaymentMethodsQuery();

  const removePaymentMethodMutation = useRemovePaymentMethodMutation();

  const setPaymentMethodAsPrimaryMutation =
    useSetPaymentMethodAsPrimaryMutation();

  function handleAddCardSuccess() {
    setIsShowingAddCardForm(false);
    queryClient.removeQueries({ queryKey: [PAYMENT_MENTHODS_QUERY_KEY] });
    notify("You have successfully added a new card");
    setUserHasCardOnFile();
  }

  usePageEventTracking();

  useDocumentTitle(PageTitle.ACCOUNT_PAYMENT_METHODS);

  if (
    paymentMethodsQuery.isLoading ||
    !paymentMethodsQuery.data ||
    removePaymentMethodMutation.isPending ||
    setPaymentMethodAsPrimaryMutation.isPending
  ) {
    return <Loader />;
  }

  return (
    <>
      <Header className="mb-6">Payment Methods</Header>

      <p className="mb-2">
        We require a valid credit or debit card on file for No Show and Late
        Cancellation fees within 24 hours of your appointment start time.
      </p>

      <p className="mb-8">
        Visit{" "}
        <Link href={ExternalLinks.TERMS} target="_blank">
          restore.com/terms
        </Link>{" "}
        for more information.
      </p>

      <Header className="mb-6 text-xl" as="h2">
        Cards on file
      </Header>

      {paymentMethodsQuery.data.length ? (
        <ul className="mb-6 flex flex-col gap-2">
          {paymentMethodsQuery.data.map((paymentMethod) => (
            <PaymentMethodItem
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              isExpanded={expandedPaymentMethodId === paymentMethod.id}
              onToggleExpanded={() =>
                setExpandedPaymentMethodId((id) =>
                  id === paymentMethod.id ? null : paymentMethod.id,
                )
              }
              onSetAsDefault={() =>
                setPaymentMethodAsPrimaryMutation.mutate(paymentMethod.id, {
                  onSuccess: () => {
                    setExpandedPaymentMethodId(null);
                    notify("Default card has been updated");
                  },
                })
              }
              onRemove={() =>
                removePaymentMethodMutation.mutate(paymentMethod.id, {
                  onSuccess: () => {
                    notify("Card has been removed");
                  },
                })
              }
            />
          ))}
        </ul>
      ) : (
        <p className="mb-4">
          We don’t currently have a card on file for you. Please add a new card
          below.
        </p>
      )}

      <div className="bg-linx-white p-4">
        <div className="flex items-center justify-between">
          <Header className="text-2xl" as="h2">
            Add a new card
          </Header>
          <button
            onClick={() => setIsShowingAddCardForm((s) => !s)}
            type="button"
            className="flex h-[40px] w-[40px] items-center justify-center rounded-full border border-amalfi-coast bg-white text-amalfi-coast"
            aria-label="Toggle add card form"
            aria-expanded={isShowingAddCardForm ? "true" : "false"}
          >
            {isShowingAddCardForm ? (
              <UpIcon aria-hidden />
            ) : (
              <PlusIcon aria-hidden />
            )}
          </button>
        </div>

        {isShowingAddCardForm && (
          <div className="mt-6">
            <AddCardForm onSuccess={handleAddCardSuccess} />
          </div>
        )}
      </div>
    </>
  );
}

export default function PaymentMethodsPage() {
  return (
    <StandardContent>
      <PaymentMethods />
    </StandardContent>
  );
}
