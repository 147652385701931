const Page = {
  ACCOUNT_DELETE: "ACCOUNT_DELETE",
  ACCOUNT_PAYMENT_METHODS: "ACCOUNT_PAYMENT_METHODS",
  ACCOUNT_PROFILE: "ACCOUNT_PROFILE",
  ACCOUNT_SECURITY: "ACCOUNT_SECURITY",
  APPOINTMENT_BOOK_DETAILS: "APPOINTMENT_BOOK_DETAILS",
  APPOINTMENT_BOOK: "APPOINTMENT_BOOK",
  APPOINTMENT_CANCEL: "APPOINTMENT_CANCEL",
  APPOINTMENT_CANCELED: "APPOINTMENT_CANCELED",
  APPOINTMENT_CONFIRM: "APPOINTMENT_CONFIRM",
  APPOINTMENT_SUCCESS: "APPOINTMENT_SUCCESS",
  APPOINTMENT: "APPOINTMENT",
  AUTH_EMAIL: "AUTH_EMAIL",
  AUTH_PASSWORD_RESET_SET: "AUTH_PASSWORD_RESET_SET",
  AUTH_PASSWORD_RESET: "AUTH_PASSWORD_RESET",
  AUTH_SET_PASSWORD: "AUTH_SET_PASSWORD",
  AUTH_SIGN_IN_CODE: "AUTH_SIGN_IN_CODE",
  AUTH_SIGN_IN_WITH_CODE: "AUTH_SIGN_IN_WITH_CODE",
  AUTH_SIGN_IN: "AUTH_SIGN_IN",
  AUTH_SIGN_UP: "AUTH_SIGN_UP",
  AUTH: "AUTH",
  HOME: "HOME",
  STAFF_CLIENT_ONE_TIME_CODE: "STAFF_CLIENT_ONE_TIME_CODE",
  WELLNESS_PLAN: "WELLNESS_PLAN",
  WELLNESS_PLAN_HISTORY: "WELLNESS_PLAN_HISTORY",
  REQUIRED_INFO: "REQUIRED_INFO",
} as const;

export const PageRoute = {
  [Page.AUTH]: "/auth",
  [Page.AUTH_SIGN_IN]: "/auth/sign-in",
  [Page.AUTH_SIGN_IN_CODE]: "/auth/sign-in/code",
  [Page.AUTH_SIGN_UP]: "/auth/sign-up",
  [Page.AUTH_PASSWORD_RESET_SET]: "/auth/password-reset/set",
  [Page.AUTH_PASSWORD_RESET]: "/auth/password-reset",
  [Page.APPOINTMENT]: (id: string) => `/appointments/${id}`,
  [Page.APPOINTMENT_CANCELED]: (id: string) => `/appointments/${id}/canceled`,
  [Page.APPOINTMENT_CANCEL]: (id: string) => `/appointments/${id}/cancel`,
  [Page.ACCOUNT_PROFILE]: "/profile",
  [Page.ACCOUNT_SECURITY]: "/account-security",
  [Page.ACCOUNT_PAYMENT_METHODS]: "/payment-methods",
  [Page.ACCOUNT_DELETE]: "/delete-account",
  [Page.HOME]: "/",
  [Page.APPOINTMENT_BOOK]: "/book",
  [Page.STAFF_CLIENT_ONE_TIME_CODE]: "/auth/one-time-code",
  [Page.WELLNESS_PLAN]: "/plans/wellness/start",
  [Page.WELLNESS_PLAN_HISTORY]: "/plans/wellness/history",
  [Page.REQUIRED_INFO]: "/auth/required-info",
} as const;

const PAGE_TITLE_SUFFIX = "| Restore";

export const PageTitle = {
  [Page.ACCOUNT_DELETE]: `Delete account ${PAGE_TITLE_SUFFIX}`,
  [Page.ACCOUNT_PAYMENT_METHODS]: `Payment methods ${PAGE_TITLE_SUFFIX}`,
  [Page.APPOINTMENT_SUCCESS]: `Appointment booked ${PAGE_TITLE_SUFFIX}`,
  [Page.APPOINTMENT_BOOK_DETAILS]: `Book an appointment ${PAGE_TITLE_SUFFIX}`,
  [Page.APPOINTMENT_CONFIRM]: `Confirm your appointment ${PAGE_TITLE_SUFFIX}`,
  [Page.HOME]: `Home ${PAGE_TITLE_SUFFIX}`,
  [Page.ACCOUNT_PROFILE]: `Profile ${PAGE_TITLE_SUFFIX}`,
  [Page.AUTH_SIGN_IN_WITH_CODE]: `Sign in with code ${PAGE_TITLE_SUFFIX}`,
  [Page.AUTH_EMAIL]: `Email ${PAGE_TITLE_SUFFIX}`,
  [Page.AUTH_SIGN_IN]: `Sign in ${PAGE_TITLE_SUFFIX}`,
  [Page.AUTH_SIGN_UP]: `Sign up ${PAGE_TITLE_SUFFIX}`,
  [Page.AUTH_SET_PASSWORD]: `Set your new password ${PAGE_TITLE_SUFFIX}`,
  [Page.AUTH_PASSWORD_RESET]: `Password reset ${PAGE_TITLE_SUFFIX}`,
  [Page.ACCOUNT_SECURITY]: `Account security ${PAGE_TITLE_SUFFIX}`,
  [Page.APPOINTMENT_CANCEL]: (serviceDescription: string) =>
    `Cancel ${serviceDescription} Appointment ${PAGE_TITLE_SUFFIX}`,
  [Page.APPOINTMENT_CANCELED]: (serviceDescription: string) =>
    `${serviceDescription} Appointment Canceled ${PAGE_TITLE_SUFFIX}`,
  [Page.APPOINTMENT]: (serviceDescription: string) =>
    `${serviceDescription} Appointment ${PAGE_TITLE_SUFFIX}`,
  [Page.STAFF_CLIENT_ONE_TIME_CODE]: `Sign in with code ${PAGE_TITLE_SUFFIX}`,
  [Page.WELLNESS_PLAN]: `My Wellness Plan ${PAGE_TITLE_SUFFIX}`,
  [Page.WELLNESS_PLAN_HISTORY]: `Wellness Plan History ${PAGE_TITLE_SUFFIX}`,
  [Page.REQUIRED_INFO]: `Required Info ${PAGE_TITLE_SUFFIX}`,
} as const;
