type Place = {
  address: string;
  city: string;
  state: string;
  postal_code: string;
};

export function parseGooglePlace(place: google.maps.places.PlaceResult) {
  return place.address_components?.reduce<Place>(
    (acc, component) => {
      if (component.types.includes("street_number")) {
        acc.address = component.long_name;
      } else if (component.types.includes("route")) {
        acc.address += " " + component.long_name;
      } else if (component.types.includes("locality")) {
        acc.city = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        acc.state = component.short_name;
      } else if (component.types.includes("postal_code")) {
        acc.postal_code = component.long_name;
      }

      return acc;
    },
    {
      address: "",
      city: "",
      state: "",
      postal_code: "",
    },
  );
}
