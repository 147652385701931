import { fetchJSONFactory } from "@/utils/fetch-json";

const fetchJSON = fetchJSONFactory(import.meta.env.VITE_BOOKING_API);

type AppointmentService = {
  name: string;
  id: number;
  cancel_requires_payment: boolean;
};

type AppointmentType = {
  category: string;
  services: AppointmentService[];
};

export type FetchServicesResponse = AppointmentType[];

export function fetchServices(storeCode: string) {
  return fetchJSON<FetchServicesResponse>(`booking/store_services`, {
    params: { store_code: storeCode },
  });
}

export type Store = {
  store_code: string;
  is_pow: true;
  description: string;
  email: string;
  phone: string;
  location: {
    address: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };
  coordinates: {
    longitude: number;
    latitude: number;
  };
};

export type FetchStoreResponse = Store;

export function fetchStore(storeCode: string) {
  return fetchJSON<FetchStoreResponse>(`stores/store`, {
    params: { store_code: storeCode },
  });
}

type AvailableTimesItem = {
  appointment_datetime: string;
  display_time: string;
  group: "Morning" | "Afternoon" | "Evening";
};

export type FetchAvailableTimesResponse = {
  store: number;
  service: number;
  times: AvailableTimesItem[];
};

export function fetchAvailableTimes(
  storeCode: string,
  serviceId: string,
  date: string,
) {
  return fetchJSON<FetchAvailableTimesResponse>(`booking/available_times`, {
    params: {
      store_code: storeCode,
      service: serviceId,
      date,
    },
  });
}

type BookAppointmentData = {
  storeCode: string;
  serviceId: string;
  time: string;
  notes: string;
};

export type BookAppointmentResponse = {
  service_name: string;
  appointment: number;
  start: string;
  duration: number;
  store: {
    id: number;
    name: string;
    email: string;
    phone: string;
    location: { address: string; city: string; state: string; zip: string };
  };
};

export async function bookAppointment({
  storeCode,
  serviceId,
  notes,
  time,
}: BookAppointmentData) {
  try {
    return await fetchJSON<BookAppointmentResponse>(`booking/appointment`, {
      body: {
        notes,
        store_code: storeCode,
        service_id: Number(serviceId),
        time,
      },
      method: "POST",
    });
  } catch (error) {
    if (error instanceof Error && error.message === "NO_SLOTS_AVAILABLE") {
      throw new Error("Sorry, that day/time is no longer available");
    }

    throw error;
  }
}

export type Appointment = {
  id: number;
  start_datetime: string;
  start_datetime_tz: string;
  end_datetime: string;
  end_datetime_tz: string;
  duration: number;
  service_id: number;
  category: number;
  service_description: string;
  store_code: string;
  store_description: string;
  store_tz: string;
  is_first_time: boolean;
  is_cancelled: boolean;
  should_warn_before_cancel: boolean;
};

export type FetchDashboardResponse = {
  upcoming: Appointment[];
  actions: {
    has_completed_wellness_plan: boolean;
    needs_waiver: boolean;
  };
  book_appointment_store: {
    store_code: string;
    is_pow: boolean;
    description: string;
    email: string;
    phone: string;
    location: {
      address: string;
      address2: string;
      city: string;
      state: string;
      zip: string;
    };
    coordinates: {
      longitude: number;
      latitude: number;
    };
    is_live: boolean;
    is_visible: boolean;
  };
};

export function fetchDashboard() {
  return fetchJSON<FetchDashboardResponse>(`dashboard/web`);
}

export type FetchFeaturesResponse = {
  wellnessPlan: true;
};

export function fetchFeatures() {
  return fetchJSON<FetchFeaturesResponse>("dashboard/feature-flags");
}

export type FetchServiceRecommendationsResponse = {
  name: string;
  service_id: number;
}[];

export function fetchServiceRecommendations() {
  return fetchJSON<FetchServiceRecommendationsResponse>(
    "booking/service_recommendations",
  );
}

type FetchAppointmentResponse = Appointment;

export function fetchAppointment(id?: string) {
  return fetchJSON<FetchAppointmentResponse>(`booking/appointments/${id}`);
}

export function cancelAppointment(id: string) {
  return fetchJSON<BookAppointmentResponse>(
    `booking/appointments/${id}/cancel`,
    {
      method: "PUT",
    },
  );
}
