import React, { useMemo } from "react";
import { twMerge } from "tailwind-merge";

type BaseProps<T extends React.ElementType> =
  React.ComponentPropsWithoutRef<T> & {
    as?: T;
    className?: string;
  };

export function IconButton<T extends React.ElementType>({
  as,
  className,
  ...rest
}: BaseProps<T>) {
  const Component = as ?? "button";
  return (
    <Component
      className={twMerge(
        "border text-amalfi-coast rounded-full w-[40px] h-[40px] flex items-center justify-center border-amalfi-coast bg-white",
        className,
      )}
      {...rest}
    />
  );
}

type Props<T extends React.ElementType> = BaseProps<T> & {
  variant?: "primary" | "secondary";
  color?: "blue" | "red" | "white";
};

export default function Button<T extends React.ElementType>({
  as,
  className,
  variant = "primary",
  color = "blue",
  ...rest
}: Props<T>) {
  const Component = as ?? "button";
  const sharedClassNames =
    "rounded-full px-4 py-3 font-semibold leading-tight text-center text-sm";

  const colorClasses = useMemo(() => {
    switch (variant) {
      case "primary":
        switch (color) {
          case "red":
            return "border-sunset-orange bg-sunset-orange hover:bg-sunset-orange/90";
          case "white":
            return "text-amalfi-coast border-amalfi-coast bg-child-of-light focus:outline-studer-blue hover:bg-child-of-light/90";
          default:
          case "blue":
            return "border-amalfi-coast bg-amalfi-coast focus:outline-studer-blue hover:bg-amalfi-coast/90";
        }
      case "secondary":
        switch (color) {
          case "blue":
            return "border-amalfi-coast text-amalfi-coast hover:enabled:bg-amalfi-coast/90";
          case "red":
            return "border-sunset-orange text-sunset-orange hover:enabled:bg-sunset-orange/90 hover:enabled:text-white";
        }
    }
  }, [variant, color]);

  if (variant === "secondary") {
    return (
      <Component
        className={twMerge(
          "border-2 disabled:border-gray-300 disabled:text-gray-400 hover:enabled:text-gray-100",
          colorClasses,
          sharedClassNames,
          className,
        )}
        {...rest}
      />
    );
  } else {
    return (
      <Component
        className={twMerge(
          "border-2 text-white disabled:bg-gray-200 disabled:border-gray-300 disabled:text-gray-400",
          colorClasses,
          sharedClassNames,
          className,
        )}
        {...rest}
      />
    );
  }
}
