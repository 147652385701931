import { fetchJSONFactory } from "@/utils/fetch-json";

const fetchJSON = fetchJSONFactory(import.meta.env.VITE_USERS_API);

type CreateIntentResponse = {
  secret: string;
  customer_guid: string;
};

export function createIntent() {
  return fetchJSON<CreateIntentResponse>(`payments/intents/client_secret`, {
    method: "POST",
  });
}

export type PaymentMethodIssuer =
  | "VISA"
  | "MASTERCARD"
  | "AMEX"
  | "DISCOVER"
  | "DINERS"
  | "JCB";

export type PaymentMethod = {
  id: number;
  customer_guid: string;
  type: "CREDIT_CARD";
  status: "EXPIRED" | "FAILED_LAST_CHARGE" | "ACTIVE";
  last4: string;
  issuer: PaymentMethodIssuer;
  is_primary: boolean;
  last_charge_at: string | null;
  expires: string;
};

type ConfirmIntentRequest = {
  payment_method_id: string;
  primary: boolean;
};

type ConfirmIntentResponse = PaymentMethod[];

export function confirmIntent(data: ConfirmIntentRequest) {
  return fetchJSON<ConfirmIntentResponse>("payments/intents/confirm", {
    body: data,
    method: "POST",
  });
}

type GetPaymentMethodsResponse = PaymentMethod[];

export function getPaymentMethods() {
  return fetchJSON<GetPaymentMethodsResponse>("payments/cards");
}

type DeletePaymentMethodResponse = {
  id: number;
  customer_guid: string;
  success: boolean;
};

export function deletePaymentMethod(id: number) {
  return fetchJSON<DeletePaymentMethodResponse>(`payments/cards/${id}`, {
    method: "DELETE",
  });
}

type SetPaymentMethodAsPrimaryResponse = PaymentMethod;

export function setPaymentMethodAsPrimary(id: number) {
  return fetchJSON<SetPaymentMethodAsPrimaryResponse>(
    `payments/cards/${id}/primary`,
    {
      method: "PUT",
    },
  );
}
