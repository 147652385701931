import React from "react";
import { twMerge } from "tailwind-merge";

type Props = React.ComponentPropsWithoutRef<"p"> & {
  error?: unknown;
  className?: string;
};

export default function ErrorText({ className, error, ...rest }: Props) {
  const cn = twMerge("text-xs italic text-red-500", className);

  if (error && error instanceof Error) {
    return (
      <p className={cn} {...rest}>
        {error.message}
      </p>
    );
  }

  return <p className={cn} {...rest} />;
}
