import { useDocumentTitle } from "@uidotdev/usehooks";
import { useEffect } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";

import { StandardContent } from "@/components/App";
import Button from "@/components/Button";
import Header from "@/components/Header";
import Icon from "@/components/Icon";
import Loader from "@/components/Loader";
import { PageRoute, PageTitle } from "@/constants/pages";
import { usePageEventTracking } from "@/hooks/event-tracking";
import { useGetPlans } from "@/hooks/wellness-plan";

function WellnessPlanHistory() {
  const getPlansQuery = useGetPlans();
  const navigate = useNavigate();

  usePageEventTracking();

  useDocumentTitle(PageTitle.WELLNESS_PLAN_HISTORY);

  useEffect(() => {
    if (getPlansQuery.isError) {
      navigate(PageRoute.HOME);
    }
  }, [navigate, getPlansQuery.isError]);

  if (getPlansQuery.isError) {
    return <Navigate to={PageRoute.HOME} />;
  }

  if (getPlansQuery.isPending || !getPlansQuery.data) {
    return <Loader />;
  }

  return (
    <>
      <Header className="mb-6">Wellness Plan History</Header>

      {getPlansQuery.data.length === 0 ? (
        <p className="mb-6">You do not have Wellness Plans to review.</p>
      ) : (
        <ul className="mb-6 border-t border-gray-300">
          {getPlansQuery.data.map((plan, index) => (
            <li key={plan.timestamp}>
              <NavLink
                to={`/plans/${plan.questionnaire_slug}/${plan.timestamp}`}
                className="flex items-center gap-1 border-b border-gray-300 py-2"
              >
                <div className="flex-1 font-semibold text-amalfi-coast">
                  {plan.questions
                    .map((question) => question.answer)
                    .join(" | ")}
                </div>

                {index === 0 && (
                  <div className="mx-5 rounded-full bg-[#70BBD5] px-2 py-1 text-xs text-white">
                    Active
                  </div>
                )}

                <Icon
                  name="arrow"
                  className="flex-none rotate-180"
                  aria-hidden
                />
              </NavLink>
            </li>
          ))}
        </ul>
      )}

      <Button
        as={NavLink}
        to={PageRoute.WELLNESS_PLAN}
        className="block w-full text-center"
      >
        Create a New Wellness Plan
      </Button>
    </>
  );
}

export default function WellnessPlanHistoryPage() {
  return (
    <StandardContent>
      <WellnessPlanHistory />
    </StandardContent>
  );
}
