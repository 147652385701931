import React from "react";
import { twMerge } from "tailwind-merge";

import downArrowIcon from "./down-arrow.svg";

const sharedClassNames =
  "border border-cold-and-dark/20 rounded-lg p-3 text-gray-600 focus:border-studer-blue focus:outline-none disabled:text-gray-400 accent-amalfi-coast";

type TextareaProps = React.HTMLProps<HTMLTextAreaElement> & {
  className?: string;
};

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...rest }: TextareaProps, ref) => {
    return (
      <textarea
        className={twMerge(sharedClassNames, className)}
        {...rest}
        ref={ref}
      />
    );
  },
);

type SelectProps = React.HTMLProps<HTMLSelectElement> & {
  className?: string;
};

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ className, ...rest }: SelectProps, ref) => {
    return (
      <select
        className={twMerge(
          "bg-transparent bg-[center_right_1rem] bg-no-repeat appearance-none",
          sharedClassNames,
          className,
        )}
        style={{
          backgroundImage: `url('${downArrowIcon}')`,
        }}
        {...rest}
        ref={ref}
      />
    );
  },
);

type InputProps = React.HTMLProps<HTMLInputElement> & {
  className?: string;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...rest }: InputProps, ref) => {
    return (
      <input
        className={twMerge(sharedClassNames, className)}
        {...rest}
        ref={ref}
      />
    );
  },
);
