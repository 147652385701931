/* eslint-disable tailwindcss/no-custom-classname */
import { twMerge } from "tailwind-merge";

type Props = React.HTMLProps<HTMLDivElement>;

function Loader({ className, ...props }: Props) {
  return (
    <div
      {...props}
      className={twMerge("flex justify-center", className)}
      role="alert"
      aria-live="assertive"
    >
      <style>{`.spinner{animation:spinner .8s linear infinite}.spinner2{animation-delay:-.65s}.spinner3{animation-delay:-.5s}@keyframes spinner{93.75%,100%{r:3px}46.875%{r:.2px}}`}</style>
      <svg
        width="44"
        height="44"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
      >
        <title>Loading...</title>
        <circle className="spinner" cx="4" cy="12" r="3" />
        <circle className="spinner spinner2" cx="12" cy="12" r="3" />
        <circle className="spinner spinner3" cx="20" cy="12" r="3" />
      </svg>
    </div>
  );
}

export default Loader;
