import { zodResolver } from "@hookform/resolvers/zod";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import Button from "@/components/Button";
import DownloadAppCTA from "@/components/DownloadAppCTA";
import FormField from "@/components/FormField";
import FormFields from "@/components/FormFields";
import Header from "@/components/Header";
import { Input } from "@/components/Input";
import Label from "@/components/Label";
import Loader from "@/components/Loader";
import { useNotification } from "@/components/Notification";
import { PageRoute, PageTitle } from "@/constants/pages";
import {
  useEmailStatusMutation,
  useRequestSignInCodeMutation,
} from "@/hooks/user";

const schema = z.object({
  email: z.string().min(1, { message: "Email is required" }).email({
    message: "Must be a valid email",
  }),
});

type Schema = z.infer<typeof schema>;

export default function EmailPage() {
  const [searchParams] = useSearchParams();
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: searchParams.get("email") ?? "",
    },
  });
  const navigate = useNavigate();
  const notify = useNotification();

  const emailStatusMutation = useEmailStatusMutation();
  const requestSignInCodeMutation = useRequestSignInCodeMutation();

  const onSubmit = handleSubmit(({ email }) => {
    const search = new URLSearchParams({
      email,
    }).toString();

    emailStatusMutation.mutate(email, {
      onSettled: (data) => {
        if (data?.customer) {
          if (data.has_password) {
            navigate({
              pathname: PageRoute.AUTH_SIGN_IN,
              search,
            });
          } else {
            requestSignInCodeMutation.mutate(email, {
              onSuccess: () => {
                notify(`We sent a verification code to ${email}`);

                navigate({
                  pathname: PageRoute.AUTH_SIGN_IN_CODE,
                  search,
                });
              },
            });
          }
        } else {
          navigate({
            pathname: PageRoute.AUTH_SIGN_UP,
            search,
          });
        }
      },
    });
  });

  useDocumentTitle(PageTitle.AUTH_EMAIL);

  const isPending =
    emailStatusMutation.isPending || requestSignInCodeMutation.isPending;

  return (
    <>
      <form onSubmit={onSubmit} className="mb-8">
        <Header className="mb-5">Welcome to Restore</Header>

        <Header className="mb-3 text-xl" as="h2">
          Let’s get started
        </Header>

        <FormFields>
          <FormField>
            <Label htmlFor="email">Please enter your email to continue</Label>
            <Input
              id="email"
              type="email"
              autoComplete="email"
              {...register("email")}
              disabled={isPending}
            />
          </FormField>
          {isPending ? (
            <Loader />
          ) : (
            <Button type="submit" disabled={!isValid}>
              Next
            </Button>
          )}
        </FormFields>
      </form>
      <DownloadAppCTA />
    </>
  );
}
