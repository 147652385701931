import ArrowIcon from "./arrow.svg?react";
import CalendarIcon from "./calendar.svg?react";
import ClockIcon from "./clock.svg?react";
import MailIcon from "./mail.svg?react";
import MapIcon from "./map.svg?react";
import PencilIcon from "./pencil.svg?react";
import PhoneIcon from "./phone.svg?react";
import RestoreIcon from "./restore.svg?react";

export default function Icon({
  name,
  ...props
}: {
  name:
    | "calendar"
    | "mail"
    | "phone"
    | "map"
    | "pencil"
    | "arrow"
    | "clock"
    | "restore";
} & React.ComponentPropsWithoutRef<"svg">) {
  switch (name) {
    case "calendar":
      return <CalendarIcon {...props} />;
    case "mail":
      return <MailIcon {...props} />;
    case "phone":
      return <PhoneIcon {...props} />;
    case "map":
      return <MapIcon {...props} />;
    case "pencil":
      return <PencilIcon {...props} />;
    case "arrow":
      return <ArrowIcon {...props} />;
    case "clock":
      return <ClockIcon {...props} />;
    case "restore":
      return <RestoreIcon {...props} />;
  }
}
