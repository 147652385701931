import * as Sentry from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import Loader from "@/components/Loader";
import { useUserQuery } from "@/hooks/user";
import { fetchUser, SignInResponse } from "@/services/user";
import { isSet } from "@/utils/helpers";

import { StandardContent } from "../App";

const MISSING_REQUIRED_FIELDS_KEY = "missingRequiredFields";

type AuthContextType = {
  guid?: string;
  user?: Awaited<ReturnType<typeof fetchUser>>;
  userQuery: ReturnType<typeof useUserQuery>;
  isSignedIn: boolean;
  isLoading: boolean;
  logout: () => void;
  signInWithToken: (res: SignInResponse) => void;
  missingRequiredFields?: boolean;
  hasIncompletedAccountInformation: boolean;
  waiver: {
    state: WaiverState;
    expiresAt?: Date;
  };
  onCompletedRequiredFields: () => void;
};

const AuthContext = createContext({} as AuthContextType);

type WaiverState = "VALID" | "EXPIRED" | "NOT_SIGNED";

export function AuthProvider({ children }: { children: JSX.Element }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [guid, setGuid] = useState<string | undefined>(undefined);
  const [token, setToken] = useState<string | undefined>(Cookies.get("token"));
  const [missingRequiredFields, setMissingRequiredFields] = useState<
    boolean | undefined
  >(localStorage.getItem(MISSING_REQUIRED_FIELDS_KEY) === "true");

  useEffect(() => {
    if (!token) {
      setGuid(undefined);
      Sentry.setUser(null);
    }
  }, [token]);

  const handleLogout = useCallback(() => {
    setToken(undefined);
    setMissingRequiredFields(undefined);
    Cookies.remove("token");
    queryClient.clear();
  }, [queryClient]);

  const userQuery = useUserQuery(token);

  useEffect(() => {
    if (userQuery.isError) {
      handleLogout();
    }
  }, [userQuery.isError, handleLogout]);

  useEffect(() => {
    if (userQuery.isSuccess) {
      Sentry.setUser({ email: userQuery.data.email });
      setGuid(userQuery.data.guid);
    }
  }, [userQuery.isSuccess, userQuery.data]);

  const waiverExpiresAt = userQuery.data?.core_waiver_expires
    ? new Date(userQuery.data.core_waiver_expires)
    : undefined;

  const waiverState = useMemo<WaiverState>(() => {
    if (userQuery.data?.has_valid_core_waiver) {
      return "VALID";
    } else {
      if (userQuery.data?.core_waiver_expires) {
        return "EXPIRED";
      } else {
        return "NOT_SIGNED";
      }
    }
  }, [userQuery.data]);

  const hasIncompletedAccountInformation = useMemo(() => {
    if (userQuery.data) {
      return !(
        userQuery.data.first_name &&
        userQuery.data.last_name &&
        userQuery.data.address &&
        userQuery.data.city &&
        userQuery.data.state &&
        userQuery.data.postal_code &&
        userQuery.data.emergency_name &&
        userQuery.data.emergency_phone &&
        userQuery.data.gender &&
        userQuery.data.mobile_phone &&
        userQuery.data.dob
      );
    } else {
      return false;
    }
  }, [userQuery.data]);

  const value: AuthContextType = {
    isSignedIn: !!token,
    guid,
    isLoading: userQuery.isLoading,
    user: userQuery.data,
    logout: handleLogout,
    waiver: {
      state: waiverState,
      expiresAt: waiverExpiresAt,
    },
    hasIncompletedAccountInformation,
    signInWithToken: (res: SignInResponse) => {
      const { token, missingRequiredFields } = res;

      queryClient.clear();

      setMissingRequiredFields(missingRequiredFields);
      setToken(token);

      Cookies.set("token", token, {
        expires: 182,
        secure: true,
      });

      //there seems to be an issue on IOS, namely IPAD where setting the cookie and then redirecting
      //doesn't actually set the cookie. Attempting to set and then read
      Cookies.get("token");

      if (missingRequiredFields) {
        localStorage.setItem(
          MISSING_REQUIRED_FIELDS_KEY,
          missingRequiredFields.toString(),
        );
        navigate("/auth/required-info", { replace: true });
        return;
      }

      localStorage.removeItem(MISSING_REQUIRED_FIELDS_KEY);

      const from = localStorage.getItem("from");
      const redirect = localStorage.getItem("redirect");

      localStorage.removeItem("from");
      localStorage.removeItem("redirect");

      if (redirect) {
        setTimeout(() => {
          window.location.href = redirect;
        }, 500);
      } else {
        navigate(from ? (JSON.parse(from) as Location) : "/", {
          replace: true,
        });
      }
    },
    missingRequiredFields: missingRequiredFields,
    onCompletedRequiredFields: () => {
      const from = localStorage.getItem("from");
      const redirect = localStorage.getItem("redirect");

      localStorage.removeItem("from");
      localStorage.removeItem("redirect");
      localStorage.removeItem(MISSING_REQUIRED_FIELDS_KEY);
      setMissingRequiredFields(false);

      if (redirect) {
        setTimeout(() => {
          window.location.href = redirect;
        }, 500);
      } else {
        navigate(from ? (JSON.parse(from) as Location) : "/", {
          replace: true,
        });
      }
    },
    userQuery: userQuery,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}

export function RequireAuth() {
  const { isSignedIn, isLoading, missingRequiredFields } = useAuth();
  const location = useLocation();

  if (missingRequiredFields) {
    const existingFrom = localStorage.getItem("from");
    // only set if it's not already set from a previous unauthenticated state
    if (!isSet(existingFrom)) {
      localStorage.setItem("from", JSON.stringify(location));
    }
    return <Navigate to="/auth/required-info" replace />;
  }

  if (!isSignedIn) {
    localStorage.setItem("from", JSON.stringify(location));
    return <Navigate to="/auth" replace />;
  }

  if (isLoading) {
    return (
      <StandardContent>
        <Loader />
      </StandardContent>
    );
  }

  return <Outlet />;
}
