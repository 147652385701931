import { zodResolver } from "@hookform/resolvers/zod";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { StandardContent } from "@/components/App";
import Button from "@/components/Button";
import ErrorText from "@/components/ErrorText";
import FormField from "@/components/FormField";
import FormFields from "@/components/FormFields";
import Header from "@/components/Header";
import { Input } from "@/components/Input";
import Label from "@/components/Label";
import Link from "@/components/Link";
import Loader from "@/components/Loader";
import { PageTitle } from "@/constants/pages";
import {
  useCreateDeletionRequestMutation,
  useDeletionRequestQuery,
} from "@/hooks/user";

const State = {
  Initial: "initial",
  Loading: "loading",
  Confirm: "confirm",
  Deleted: "deleted",
  HasMembershipOrCredits: "hasMembershipOrCredits",
} as const;

function InitialDeleteAccountView({ onDelete }: { onDelete: () => void }) {
  return (
    <div className="flex flex-col">
      <Header className="mb-6">Request to delete Restore account</Header>

      <p className="mb-2">
        At any time or for any reason you may send a request to delete your
        account from Restore systems.
      </p>

      <p className="mb-8">All requests will be fulfilled within 45 days.</p>

      <div className="mb-8 rounded-md bg-amalfi-coast/5 p-4">
        <p className="mb-2 font-semibold">Please note:</p>
        <p className="mb-2">
          Any credits and packs that remain unused at the time of deletion will
          be forfeited.
        </p>
      </div>
      <Button type="button" onClick={onDelete}>
        Request to delete account
      </Button>
    </div>
  );
}

const schema = z.object({
  password: z.string().min(1, { message: "Password is required" }),
});

type Schema = z.infer<typeof schema>;

function ConfirmDeleteAccountView({ onCancel }: { onCancel: () => void }) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const createDeletionRequestMutation = useCreateDeletionRequestMutation();
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const onSubmit = handleSubmit(({ password }) => {
    setErrorMessage(null);
    createDeletionRequestMutation.mutate(password);
  });

  return (
    <form onSubmit={onSubmit}>
      <Header className="mb-6">
        Are you sure you want to delete your account?
      </Header>

      <p className="mb-6">
        Please note that there will be no option to restore the original
        account, or its data once deleted from Restore systems.
      </p>

      <p className="mb-6">For your security, please enter your password</p>

      <FormFields className="mb-10">
        <FormField>
          <Label>Password</Label>
          <Input id="password" type="password" {...register("password")} />
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        </FormField>
        {createDeletionRequestMutation.isError && (
          <ErrorText error={createDeletionRequestMutation.error} />
        )}
        {createDeletionRequestMutation.isPending ? (
          <Loader />
        ) : (
          <>
            <Button
              type="submit"
              disabled={!isValid}
              variant="secondary"
              color="red"
            >
              Confirm request to delete account
            </Button>
            <Button type="button" onClick={onCancel}>
              Cancel
            </Button>
          </>
        )}
      </FormFields>
    </form>
  );
}

function DeletedAccountView() {
  return (
    <>
      <Header className="mb-6">Request to delete account received</Header>

      <p className="mb-6">
        We’ve received your request to delete your account.
      </p>

      <p className="mb-6">
        A member of our support team will be in touch by email to discuss next
        steps. Once they have verified your request, your account will be
        deleted within 30 days.
      </p>

      <p className="mb-6">
        In the meantime, please review your account for any unused memberships
        and pack sessions.
      </p>

      <p className="mb-6">
        If you did not request your account to be deleted, please contact{" "}
        <Link as="a" href="mailto:it@restore.com">
          it@restore.com
        </Link>
      </p>
    </>
  );
}

function HasMembershipOrCreditsView() {
  return (
    <>
      <Header className="mb-6">Request to delete Restore account</Header>
      <p className="mb-6">
        We can see that you currently have a live membership and/or remaining
        balance on your account.
      </p>
      <p className="mb-6">
        Before we can delete your account, please visit your studio to cancel
        your membership and/or resolve any remaining account balance.
      </p>
      <p className="mb-6">
        Once these have been resolved by your studio, please return to this page
        to complete your request for account deletion.
      </p>

      <div className="mb-8 rounded-md bg-amalfi-coast/5 p-4">
        <p className="mb-2 font-semibold">Please note:</p>
        <p className="mb-2">
          Any credits and packs that remain unused at the time of deletion will
          be forfeited.
        </p>
      </div>
    </>
  );
}

function DeleteAccount() {
  const deletionRequestQuery = useDeletionRequestQuery();
  const [isConfirming, setIsConfirming] = useState(false);

  const state = useMemo<(typeof State)[keyof typeof State]>(() => {
    if (deletionRequestQuery.isLoading) {
      return State.Loading;
    }
    if (deletionRequestQuery.data?.date_requested) {
      return State.Deleted;
    }
    if (!deletionRequestQuery.data?.can_submit_delete_request) {
      return State.HasMembershipOrCredits;
    }

    if (isConfirming) {
      return State.Confirm;
    }

    if (deletionRequestQuery.isLoading) {
      return State.Loading;
    }

    return State.Initial;
  }, [isConfirming, deletionRequestQuery.isLoading, deletionRequestQuery.data]);

  useDocumentTitle(PageTitle.ACCOUNT_DELETE);

  switch (state) {
    case State.HasMembershipOrCredits:
      return <HasMembershipOrCreditsView />;
    case State.Confirm:
      return (
        <ConfirmDeleteAccountView onCancel={() => setIsConfirming(false)} />
      );
    case State.Deleted:
      return <DeletedAccountView />;
    case State.Initial:
      return (
        <InitialDeleteAccountView onDelete={() => setIsConfirming(true)} />
      );
    default:
      return <Loader />;
  }
}

export default function DeleteAccountPage() {
  return (
    <StandardContent>
      <DeleteAccount />
    </StandardContent>
  );
}
